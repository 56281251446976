@import "../../styles/styles.scss";
@import "../../styles/mixins.scss";

.footer {
    padding: 0px;

    .recent {
        h3 {
            margin-bottom: 37px;
        }

        img {
            width: 85px;
            height: 85px;
            border-radius: 8px;
        }

        .blog {
            @include flex-container(row, flex-start, center);
            gap: 15px;
            margin-bottom: 30px;

            h4 {
                @include text-style($Outfit, 16px, 500);
            }

            h5 {
                @include text-style($dm_sans, 14px, 400);
                color: #969EB2;
            }
        }
    }

    @media screen and (max-width: 767.98px) {
        .recent{
            h3{
                margin-bottom: 18px;
            }

            .blog{
                h4{
                    margin-bottom: 13px;
                }
            }
        }
    }
}

.footer_logo {
    span{
        @include flex-container(row, flex-start, center);
        margin-bottom: 49px;
        gap: 22px;
        img{
            width: 60px;
        }
        h2 {
            @include text-style($default-font, 36px, 500);
            line-height: 24px;
            margin-bottom: 0px;
        }
    }

    svg {
        margin-right: 11px;
    }

    p {
        @include text-style($dm_sans, 16px, 400);
        line-height: 28px;
    }

    @media screen and (max-width: 767.98px) {
        padding-bottom: 53px;
        span{
            margin-bottom: 22px;
            gap: 11px;
        }
        p{
            line-height: 21px;
            margin-bottom: 5px;
        }

        .footer_text{
            margin-bottom: 16px;
        }
    }
}

.footer_top {
    background-color: $footer_bg;
    padding: 82px 0px 56px 0px;
    color: white;

    h3 {
        @include text-style($default-font, 24px, 500);
        line-height: 24px;

        &::after {
            content: '';
            width: 92px;
            height: 2px;
            border-radius: 10px;
            margin-top: 18px;
            background: linear-gradient(90deg, #1F5FFF 0%, rgba(0, 0, 0, 0) 100%);
            @include flex-container(column, center, flex-start);
        }
    }

    ul {
        list-style: none;
        padding-left: 0px;
        margin-top: 33px;

        svg {
            // margin-right: 12px;
            margin-top: 3px;
        }

        li {
            @include text-style($default-font, 16px, 400);
            line-height: 21px;
            margin-bottom: 11px;
            display: flex;
            align-items: flex-start;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 767.98px) {
        padding-top: 57px;
        padding-bottom: 19px;

        h3{
            @include text-style($default-font, 20px, 500);
            line-height: 25px;
            margin-bottom: 16px;

            &::after{
                margin-top: 5px;
            }
        }

        ul{
            margin-top: 16px;
            margin-bottom: 31px;
            li{
                line-height: 19px;
            }
        }
    }
}

.footer_bottom {
    background-color: $primary_1;
    padding: 12px 0px;

    .container {
        @include flex-container(row, space-between, center);
    }

    .row_center{
        gap: 5px;
    }

    color: white;

    p {
        @include text-style($dm_sans, 16px, 500);
        line-height: 28px;
        margin-bottom: 0px;
        a{
            color: #0abc9f !important;
        }
    }

    @media screen and (max-width: 767.98px) {
        .container {
            @include flex-container(column, center, center);
        }
        p{
            margin-bottom: 10px;
        }
    }
}

.footer_text {
    @include text-style($dm_sans, 16px, 400);
}