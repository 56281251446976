h1 {
  font-family: "Roboto", sans-serif;
  font-size: 48px;
  font-weight: bold;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 24px;
}
@media screen and (max-width: 767.98px) {
  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 29px;
  }
}

h3 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  h3 {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
}

h4 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  h4 {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

h5 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

p {
  font-family: "Roboto", sans-serif;
  line-height: 28px;
}

.btn-primary {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #407BFF;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn-primary:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-primary {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-primary:hover {
  background-color: #2769ff;
}

.btn-secondary {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #D2E7F0;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  width: 300px;
}
.btn-secondary:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-secondary {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-secondary:hover {
  background-color: #bfddea;
}

.btn-default {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  color: #407BFF;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  width: 300px;
}
.btn-default:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-default {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-default:hover {
  background-color: #f2f2f2;
}

.btn_primary_1 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #407BFF;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn_primary_1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_1 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_primary_1:hover {
  background-color: #2769ff;
}

.btn_primary_gradient {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  width: 300px;
  background: linear-gradient(94.75deg, #2E74B6 3.87%, #1F405A 84.77%);
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.btn_primary_gradient:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_gradient {
    border-radius: 5px;
    width: 200px;
  }
}

.btn_primary_2 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #D2E7F0;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn_primary_2:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_2 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_primary_2:hover {
  background-color: #bfddea;
}

.btn_secondary_1 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #0C1220;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 300px;
}
.btn_secondary_1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_secondary_1 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_secondary_1:hover {
  background-color: #05080d;
}

.btn_secondary_2 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #000;
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 158px;
}
.btn_secondary_2:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_secondary_2 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_secondary_2:hover {
  background-color: #f2f2f2;
}

.arrow_btn {
  border: none;
  background-color: #FFFFFF;
  color: #407BFF;
  width: 57px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}

.icon_round {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2509803922);
  box-shadow: 0px -1px 11px 0px rgba(0, 0, 0, 0.2509803922);
  color: #407BFF;
}

.icon_parent {
  width: 40px;
  color: #407BFF;
}

.row_between_center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row_center {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: center;
}

.row_centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.col_centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex_1 {
  flex: 1;
}

.h_100 {
  height: 100%;
}

.w_100 {
  width: 100%;
}

section {
  padding: 60px 0px;
}
@media screen and (max-width: 767.98px) {
  section {
    padding: 40px 0px;
  }
}

.form-control {
  border: 0.5px solid #407BFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
  padding: 13px 0px 13px 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}
.form-control:focus {
  box-shadow: none;
}
.form-control::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.4;
}
@media screen and (max-width: 767.98px) {
  .form-control {
    margin-bottom: 20px;
    padding: 15px 0px 16px 20px;
  }
}

.form-group {
  margin-bottom: 24px;
}
.form-group label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  margin-bottom: 8px;
}

.form-label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.patient-dashboard-parent {
  display: flex;
  gap: 56px;
  margin-top: 32px;
  margin-bottom: 56px;
}
@media screen and (max-width: 767.98px) {
  .patient-dashboard-parent {
    flex-direction: column;
    gap: 30px;
  }
}

.patient-dashboard {
  width: 365px;
  height: 585px;
  border-radius: 10px;
  box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.0901960784);
  flex-shrink: 0;
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  top: 32px;
}
.patient-dashboard .dashboard-item {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 13px;
  padding: 20px 0px 20px 32px;
  border-bottom: 1px solid rgba(48, 123, 196, 0.5019607843);
  cursor: pointer;
}
.patient-dashboard .dashboard-item img {
  width: 18px;
}
.patient-dashboard .dashboard-item:hover {
  background-color: #D2E7F0;
}
.patient-dashboard .dashboard-item.active {
  background-color: #D2E7F0;
}

.patient-dashboard-child {
  box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.0901960784);
  border-radius: 10px;
  max-height: 585px;
  overflow-y: auto;
  flex: 1;
}
.patient-dashboard-child::-webkit-scrollbar {
  display: none;
}
.patient-dashboard-child h3 {
  border-bottom: 1px solid rgba(48, 123, 196, 0.5019607843);
  padding: 16px 0px 8px 32px;
}
.patient-dashboard-child .form-container {
  padding: 32px 24px;
}
.patient-dashboard-child .button-group button {
  width: 137px;
  padding: 10px 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.patient-dashboard-child .button-group .left {
  border-radius: 5px 0px 0px 5px;
}
.patient-dashboard-child .button-group .right {
  border-radius: 0px 5px 5px 0px;
}
@media screen and (max-width: 767.98px) {
  .patient-dashboard-child .button-group {
    text-align: center;
  }
}
.patient-dashboard-child .filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 24px 3px 32px 3px;
}
.patient-dashboard-child .filters .search-box {
  border: 1px solid #407BFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  padding: 10px 0px 11px 32px;
  height: 40px;
}
@media screen and (max-width: 767.98px) {
  .patient-dashboard-child .filters {
    gap: 15px;
  }
  .patient-dashboard-child .filters .search-box {
    width: 165px;
    padding-left: 15px;
  }
}
.patient-dashboard-child .bookings-list {
  box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.0901960784);
  border-radius: 10px;
  padding: 16px 24px;
  margin-bottom: 20px;
}
.patient-dashboard-child .bookings-list .data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.patient-dashboard-child .bookings-list .data h4 {
  margin-bottom: 0px;
}
.patient-dashboard-child .bookings-list .data .left-1 {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 32px;
}
.patient-dashboard-child .bookings-list .data .left-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 4px;
}
.patient-dashboard-child .bookings-list .data .left-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  gap: 4px;
}
.patient-dashboard-child .bookings-list .data .left-3 .status {
  width: 183px;
  height: 27px;
  border-radius: 13.5px;
  background-color: #407BFF;
  padding: 4px 0px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.patient-dashboard-child .bookings-list .date-time {
  width: 80px;
  height: 73px;
  border-radius: 10px;
  background-color: #EAE8E8;
  padding: 7px 8px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.patient-dashboard-child .bookings-list .links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #D2E7F0;
  border-radius: 10px;
  padding: 11px 24px;
}
.patient-dashboard-child .bookings-list .links .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 19px;
}
.patient-dashboard-child .bookings-list .links .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 16px;
}
.patient-dashboard-child .bookings-list .links .wt_500 {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 9px;
  cursor: pointer;
  margin-bottom: 0px;
  color: #407BFF;
  text-decoration: underline;
}
.patient-dashboard-child .bookings-list .links .wt_500:hover {
  color: black;
}
.patient-dashboard-child .bookings-list .links button {
  width: 123px;
  height: 27px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  border-radius: 3px;
  padding: 4px 7px;
}
@media screen and (max-width: 767.98px) {
  .patient-dashboard-child .bookings-list {
    padding: 15px 7px;
    border-radius: 5px;
    margin: 0px 4px 20px 4px;
  }
  .patient-dashboard-child .bookings-list .links button {
    width: 97px;
    height: 23px;
    font-size: 13px;
    line-height: 15px;
    padding: 4px;
  }
  .patient-dashboard-child .bookings-list h4 {
    font-size: 13px;
    line-height: 15px;
  }
  .patient-dashboard-child .bookings-list .data .left-1 {
    gap: 10px;
  }
  .patient-dashboard-child .bookings-list .data .left-2 {
    gap: 10px;
  }
  .patient-dashboard-child .bookings-list .data .left-3 {
    gap: 10px;
  }
  .patient-dashboard-child .bookings-list .data .left-3 .status {
    width: 108px;
    height: 16px;
    border-radius: 10px;
    font-size: 10px;
  }
  .patient-dashboard-child .bookings-list .date-time {
    border-radius: 2px;
    padding: 10px 3px;
    width: 57px;
    height: 66px;
  }
}
.patient-dashboard-child .add-new {
  margin-bottom: 36px;
  cursor: pointer;
}
.patient-dashboard-child .add-new:hover {
  text-decoration: underline;
}
@media screen and (max-width: 767.98px) {
  .patient-dashboard-child .add-new {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767.98px) {
  .patient-dashboard-child {
    box-shadow: none;
    max-height: unset;
    flex: 1;
  }
  .patient-dashboard-child .form-container {
    padding: 0px;
  }
}

.wt_500 {
  font-weight: 500;
}

.img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 53px;
  border-bottom: 1px solid rgba(48, 123, 196, 0.5019607843);
}
.img-container img {
  border-radius: 50%;
  width: 126px;
  height: 126px;
}

.member-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 9px 5px rgba(0, 0, 0, 0.0901960784);
  border-radius: 10px;
  padding: 16px 24px;
  margin-bottom: 24px;
}
@media screen and (max-width: 767.98px) {
  .member-list {
    margin: 0px 7px 15px 7px;
    padding: 15px 10px;
  }
}

.member {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 24px;
}
.member span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 35px;
  border-radius: 5px;
  background-color: #D2E7F0;
  padding: 8px 9px;
}
.member .relation {
  font-weight: 700;
}
@media screen and (max-width: 767.98px) {
  .member {
    gap: 10px;
  }
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 64px;
}
.options .status {
  padding: 4px 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  line-height: 18.75px;
}
.options .status.active {
  color: #45AD0F;
}
.options .status.suspended {
  color: #F64343;
}
@media screen and (max-width: 767.98px) {
  .options {
    gap: 25px;
  }
}

.pf-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 149px;
  margin-bottom: 16px;
}
.pf-title::after {
  content: ":";
}

.pf-textarea {
  width: 259px;
}
@media screen and (max-width: 767.98px) {
  .pf-textarea {
    width: 315px;
  }
}

.pf-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 4px;
}

@media screen and (max-width: 767.98px) {
  .comments {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
}

.pf-btn {
  margin: 0px auto 8px auto;
  display: block;
  width: 300px;
}

.custom-select-container {
  width: 100%;
  max-width: 316px;
  margin: 0 auto;
}
@media screen and (max-width: 767.98px) {
  .custom-select-container {
    width: 165px;
  }
}

.pd-top {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
}
.pd-top h4 {
  margin-bottom: 0px;
  font-weight: 500;
}
.pd-top .right {
  margin-left: 8px;
}

.pd-field {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 0px 62px 24px 62px;
}
.pd-field h4 {
  margin-bottom: 0px;
}
.pd-field label {
  width: 95px;
}
.pd-field input {
  width: 257px;
  margin-bottom: 0px;
  padding: 10px 16px 11px 24px;
}
.pd-field .right {
  margin-left: 24px;
}
.pd-field .custom-select-container {
  width: 257px;
  margin: 0px;
}
@media screen and (max-width: 767.98px) {
  .pd-field {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin: 0px 0px 15px 0px;
    gap: 5px;
  }
  .pd-field input,
  .pd-field .custom-select-container {
    width: 315px;
  }
  .pd-field .right {
    margin-left: 0px;
  }
}

.pd-btn {
  margin-left: 62px;
  margin-top: 8px;
}
@media screen and (max-width: 767.98px) {
  .pd-btn {
    margin-left: 0px;
    margin-top: 13px;
  }
}

.prescription {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.prescription .upload-link {
  text-decoration: underline;
  color: #2E72B3;
}

.custom-file-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 412px;
  height: 235px;
  border-radius: 10px;
  border: 1px dashed rgba(0, 0, 0, 0.5019607843);
  background-color: #D2E7F0;
  cursor: pointer;
  gap: 16px;
}
.custom-file-upload img {
  width: 59px;
}