@import '../../styles/styles.scss';
@import '../../styles/mixins.scss';

.logo {
    width: 144px;
}

.dropdown-toggle {
    width: 332px;
    height: 48px;
    padding: 14px 41px 14px 17px;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
}

nav {
    background-color: white;
    box-shadow: 0px 4px 11px 0px #0000002B;

    @media screen and (max-width: 767.98px) {
        padding: 15px 0px !important;
    }
}

.location-box {
    @include flex-container(row, flex-start, center);

    input {
        border: none;
        outline: none;
        margin: 0px 8px 0px 10px;
    }

    max-width: 332px;
    min-width: 305px;
    height: 48px;
    padding: 14px 41px 14px 17px;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    margin-right: 8px;
    flex: 1;
}

.mobile-back{
    display: none;

    @media screen and (max-width: 767.98px) {
       display: block;
    }
}

.mobile-location-box {
    @include text-style($default-font, 13px, 400);

    input {
        border: none;
        outline: none;
        margin: 0px 5px;
    }

    max-width: 199px;
    height: 30px;
    border-radius: 4px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #E3E3E3;
    margin-right: 8px;
    flex: 1;
    display: none;

    @media screen and (max-width: 767.98px) {
        @include flex-container(row, flex-start, center);
    }
}

.search-container {
    flex: 1;
    margin-right: 24px;
}

.search-box {
    @include flex-container(row, flex-start, center);

    input {
        border: none;
        outline: none;
        margin-left: 14px;
        max-width: 78%;
    }

    // max-width: 370px;
    height: 48px;
    padding: 14px 41px 14px 17px;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    flex: 1;
}

.navbar-nav {
    flex: 1;

    svg {
        margin-top: 0px;
        color: #a4a4a4;
    }

    li {
        margin-bottom: 0px;
    }
}

.profile {
    display: block;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &::after {
        position: absolute;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #14BD6F;
        top: 4px;
        right: 4px;
    }
}

.mobile-right {
    gap: 20px;
    display: none;

    @media screen and (max-width: 767.98px) {
        @include flex-container(row, center, center);
    }
}

// BOOKING STYLES

.modal-content {
    border-radius: 10px;
}

.modal-header {
    h3 {
        margin-bottom: 0px;
    }

    svg {
        margin-left: auto;
        align-items: center;
        cursor: pointer;
    }
}

.modal-body {
    padding: 32px 16px;
    position: relative;

    .close-icon {
        position: absolute;
        right: 8px;
        top: 8px;
    }
}

.underline-pointer {
    text-decoration: underline;
    cursor: pointer;
}

.booking-modal-body {

    .booking-step {
        text-align: center;
        font-weight: 500;
        line-height: 18.75px;
        margin-bottom: 32px;
    }

    form {
        @include flex-container(column, center, center);
        margin: auto;

        .w_300 {
            width: 300px;
        }

        .form-control.mobileno{
            @include flex-container(row, flex-start, center);
            padding: 11px 0px 10px 32px;

            h4{
                @include text-style($default-font, 14px, 400);
                margin-bottom: 0px;
                line-height: 16px;
            }

            input{
                outline: none;
                border: none;
                padding: 0px;
                margin-bottom: 0px;
                margin-left: 10px;
                width: 70%;
            }
        }

        label {
            align-self: flex-start;
        }

        .underline-pointer {
            font-weight: 500;
        }

        .resend {
            line-height: 18.75px;
            margin-left: auto;
            margin-top: 8px;
        }
    }

    .mobile-underline {
        @include flex-container(row, flex-start, center);
        text-decoration: underline;
        width: 100%;
        gap: 16px;
        margin-bottom: 24px;

        h4 {
            margin-bottom: 0px;
        }

        svg {
            cursor: pointer;
        }
    }

    .mobile-label {
        margin-bottom: 16px;
    }

    input {
        border-radius: 5px;
        padding: 12px 0px 12px 32px;
        @include text-style($default-font, 14px, 400);
        line-height: 16px;
        margin-bottom: 24px;

        &:last-of-type {
            margin-bottom: 21px;
        }
    }

    button {
        @include text-style($default-font, 16px, 500);
        line-height: 18.75px;
        border-radius: 10px;
        padding: 15px 0px;
        width: 300px;
    }

    .success-heading {
        @include text-style($default-font, 16px, 500);
        color: #31C07E;
        margin-bottom: 16px;
        margin-top: 11px;
    }

    .booking-id {
        font-weight: 500;
    }

    .further {
        margin-top: 21px;
        margin-bottom: 13px;
        text-align: center
    }

    .further-actions-container {
        margin: auto;
        width: max-content;
    }

    .further-actions {
        @include flex-container(row, flex-start, center);
        color: #2E72B3;
        gap: 5px;
        margin-bottom: 16px;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.pointer-disable {
    pointer-events: none;
}

.error {
    color: red;
}

.input-box {
    display: flex;

    input,
    textarea {
        margin-bottom: 0px;
    }
}

.input-box>div {
    flex: 1;
}

.loader-container {
    text-align: center;
}

.invalid {
    color: red;
    /* You can customize additional styles here based on your requirements */
}

.invalid-error {
    border: 1px solid red;
    border-radius: 4px;
}