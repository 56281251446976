@import "../../styles/styles.scss";
@import "../../styles/mixins.scss";

.patient-dashboard-parent {
    display: flex;
    gap: 56px;
    margin-top: 32px;
    margin-bottom: 56px;
    // position: relative;

    @media screen and (max-width: 767.98px) {
        flex-direction: column;
        gap: 30px;
    }
}

.patient-dashboard {
    width: 365px;
    height: 585px;
    border-radius: 10px;
    box-shadow: 0px 0px 9px 5px #00000017;
    flex-shrink: 0;
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 32px;

    .dashboard-item {
        @include text-style($default-font, 16px, 400);
        @include flex-container(row, start, center);
        gap: 13px;
        padding: 20px 0px 20px 32px;
        border-bottom: 1px solid #307BC480;
        cursor: pointer;

        img {
            width: 18px;
        }

        &:hover {
            background-color: #D2E7F0;
        }
    }

    .dashboard-item.active {
        background-color: #D2E7F0;
    }
}

.patient-dashboard-child {
    box-shadow: 0px 0px 9px 5px #00000017;
    border-radius: 10px;
    max-height: 585px;
    overflow-y: auto;
    flex: 1;

    &::-webkit-scrollbar {
        display: none;
    }

    h3 {
        border-bottom: 1px solid #307BC480;
        padding: 16px 0px 8px 32px;
    }

    .form-container {
        padding: 32px 24px;
    }

    .button-group {

        button {
            width: 137px;
            padding: 10px 0px;
            @include text-style($default-font, 16px, 500);
        }

        .left {
            border-radius: 5px 0px 0px 5px;
        }

        .right {
            border-radius: 0px 5px 5px 0px;
        }

        @media screen and (max-width: 767.98px) {
            text-align: center;
        }
    }

    .filters {
        @include flex-container(row, center, center);
        gap: 24px;
        margin: 24px 3px 32px 3px;
        // margin-top: 24px;
        // margin-bottom: 32px;

        .search-box {
            border: 1px solid #407BFF;
            box-shadow: 0px 4px 8px 0px #00000029;
            border-radius: 5px;
            padding: 10px 0px 11px 32px;
            height: 40px;
        }

        @media screen and (max-width: 767.98px) {
            gap: 15px;

            .search-box {
                width: 165px;
                padding-left: 15px;
            }
        }
    }

    .bookings-list {
        box-shadow: 0px 0px 9px 5px #00000017;
        border-radius: 10px;
        padding: 16px 24px;
        margin-bottom: 20px;

        .data {
            @include flex-container(row, space-between, center);
            margin-bottom: 16px;

            h4 {
                margin-bottom: 0px;
            }

            .left-1 {
                @include flex-container(row, start, center);
                gap: 32px;
            }

            .left-2 {
                @include flex-container(column, space-between, start);
                gap: 4px;
            }

            .left-3 {
                @include flex-container(column, space-between, end);
                gap: 4px;

                .status {
                    width: 183px;
                    height: 27px;
                    border-radius: 13.5px;
                    background-color: #407BFF;
                    padding: 4px 0px;
                    color: white;
                    // margin: 4px 0px;
                    @include flex-container(row, center, center);
                }
            }
        }

        .date-time {
            width: 80px;
            height: 73px;
            border-radius: 10px;
            background-color: #EAE8E8;
            padding: 7px 8px;
            text-align: center;

            @include flex-container(row, center, center);
        }

        .links {
            @include flex-container(row, space-between, center);
            background-color: #D2E7F0;
            border-radius: 10px;
            padding: 11px 24px;

            .left {
                @include flex-container(column, space-between, start);
                gap: 19px;
            }

            .right {
                @include flex-container(column, space-between, start);
                gap: 16px;
            }

            .wt_500 {
                @include flex-container(row, start, center);
                gap: 9px;
                cursor: pointer;
                margin-bottom: 0px;
                color: $primary_1;
                text-decoration: underline;

                &:hover {
                    color: black;
                }
            }

            button {
                width: 123px;
                height: 27px;
                font-size: 16px;
                font-weight: 400;
                line-height: 18.75px;
                text-align: left;
                border-radius: 3px;
                padding: 4px 7px;
            }
        }

        @media screen and (max-width: 767.98px) {
            padding: 15px 7px;
            border-radius: 5px;
            margin: 0px 4px 20px 4px;

            .links {
                button {
                    width: 97px;
                    height: 23px;
                    font-size: 13px;
                    line-height: 15px;
                    padding: 4px;
                }
            }

            h4 {
                font-size: 13px;
                line-height: 15px;
            }

            .data {
                .left-1 {
                    gap: 10px;
                }

                .left-2 {
                    gap: 10px;
                }

                .left-3 {
                    gap: 10px;

                    .status {
                        width: 108px;
                        height: 16px;
                        border-radius: 10px;
                        font-size: 10px;
                    }
                }
            }

            .date-time {
                border-radius: 2px;
                padding: 10px 3px;
                width: 57px;
                height: 66px;
            }
        }
    }

    .add-new {
        margin-bottom: 36px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        @media screen and (max-width: 767.98px) {
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 767.98px) {
        box-shadow: none;
        max-height: unset;
        flex: 1;

        .form-container {
            padding: 0px;
        }
    }
}

.wt_500 {
    font-weight: 500;
}

.img-container {
    @include flex-container(row, center, center);
    padding-top: 32px;
    padding-bottom: 53px;
    border-bottom: 1px solid #307BC480;

    img {
        border-radius: 50%;
        width: 126px;
        height: 126px;
    }
}

.member-list {
    @include flex-container(row, space-between, center);
    box-shadow: 0px 0px 9px 5px #00000017;
    border-radius: 10px;
    padding: 16px 24px;
    margin-bottom: 24px;

    @media screen and (max-width: 767.98px) {
        margin: 0px 7px 15px 7px;
        padding: 15px 10px;
    }
}

.member {
    @include flex-container(row, start, center);
    gap: 24px;

    span {
        @include flex-container(row, center, center);
        width: 36px;
        height: 35px;
        border-radius: 5px;
        background-color: #D2E7F0;
        padding: 8px 9px;
    }

    .relation {
        font-weight: 700;
    }

    @media screen and (max-width: 767.98px) {
        gap: 10px;

    }
}

.options {
    @include flex-container(row, center, center);
    gap: 64px;

    .status {
        padding: 4px 10px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        line-height: 18.75px;
    }

    .status.active {
        color: #45AD0F;
    }

    .status.suspended {
        color: #F64343;
    }

    @media screen and (max-width: 767.98px) {
        gap: 25px;
    }
}

.pf-title {
    @include flex-container(row, space-between, center);
    width: 149px;
    margin-bottom: 16px;

    &::after {
        content: ':',
    }
}

.pf-textarea {
    width: 259px;

    @media screen and (max-width: 767.98px) {
        width: 315px;
    }
}

.pf-row {
    @include flex-container(row, start, start);
    gap: 4px;
}

.comments {
    @media screen and (max-width: 767.98px) {
        @include flex-container(column, start, start);
    }
}

.pf-btn {
    margin: 0px auto 8px auto;
    display: block;
    width: 300px;
}

.custom-select-container {
    width: 100%;
    max-width: 316px;
    margin: 0 auto;

    @media screen and (max-width: 767.98px) {
        width: 165px;
    }
}

.pd-top {
    @include flex-container(row, start, center);
    gap: 16px;
    margin-bottom: 32px;

    h4 {
        margin-bottom: 0px;
        font-weight: 500;
    }

    .right {
        margin-left: 8px;
    }
}

.pd-field {
    @include flex-container(row, start, center);
    margin: 0px 62px 24px 62px;

    h4 {
        margin-bottom: 0px;
    }

    label {
        width: 95px;
    }

    input {
        width: 257px;
        margin-bottom: 0px;
        padding: 10px 16px 11px 24px;
    }

    .right {
        margin-left: 24px;
    }

    .custom-select-container {
        width: 257px;
        margin: 0px;
    }

    @media screen and (max-width: 767.98px) {
        @include flex-container(column, start, start);
        margin: 0px 0px 15px 0px;
        gap: 5px;

        input,
        .custom-select-container {
            width: 315px;
        }

        .right {
            margin-left: 0px;
        }

    }
}

.pd-btn {
    margin-left: 62px;
    margin-top: 8px;

    @media screen and (max-width: 767.98px) {
        margin-left: 0px;
        margin-top: 13px;
    }
}

.prescription {
    @include flex-container(row, center, center);

    .upload-link {
        text-decoration: underline;
        color: #2E72B3;
    }
}

.custom-file-upload {
    @include flex-container(column, center, center);
    width: 412px;
    height: 235px;
    border-radius: 10px;
    border: 1px dashed #00000080;
    background-color: #D2E7F0;
    cursor: pointer;
    gap: 16px;

    img {
        width: 59px;
    }
}