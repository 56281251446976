@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
h1 {
  font-family: "Roboto", sans-serif;
  font-size: 48px;
  font-weight: bold;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 24px;
}
@media screen and (max-width: 767.98px) {
  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 29px;
  }
}

h3 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  h3 {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
}

h4 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  h4 {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

h5 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

p {
  font-family: "Roboto", sans-serif;
  line-height: 28px;
}

.btn-primary {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #407BFF;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn-primary:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-primary {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-primary:hover {
  background-color: #2769ff;
}

.btn-secondary {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #D2E7F0;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  width: 300px;
}
.btn-secondary:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-secondary {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-secondary:hover {
  background-color: #bfddea;
}

.btn-default {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  color: #407BFF;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  width: 300px;
}
.btn-default:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-default {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-default:hover {
  background-color: #f2f2f2;
}

.btn_primary_1 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #407BFF;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn_primary_1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_1 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_primary_1:hover {
  background-color: #2769ff;
}

.btn_primary_gradient {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  width: 300px;
  background: linear-gradient(94.75deg, #2E74B6 3.87%, #1F405A 84.77%);
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.btn_primary_gradient:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_gradient {
    border-radius: 5px;
    width: 200px;
  }
}

.btn_primary_2 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #D2E7F0;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn_primary_2:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_2 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_primary_2:hover {
  background-color: #bfddea;
}

.btn_secondary_1 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #0C1220;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 300px;
}
.btn_secondary_1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_secondary_1 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_secondary_1:hover {
  background-color: #05080d;
}

.btn_secondary_2 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #000;
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 158px;
}
.btn_secondary_2:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_secondary_2 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_secondary_2:hover {
  background-color: #f2f2f2;
}

.arrow_btn {
  border: none;
  background-color: #FFFFFF;
  color: #407BFF;
  width: 57px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}

.icon_round {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2509803922);
  box-shadow: 0px -1px 11px 0px rgba(0, 0, 0, 0.2509803922);
  color: #407BFF;
}

.icon_parent {
  width: 40px;
  color: #407BFF;
}

.row_between_center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row_center {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: center;
}

.row_centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.col_centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex_1 {
  flex: 1;
}

.h_100 {
  height: 100%;
}

.w_100 {
  width: 100%;
}

section {
  padding: 60px 0px;
}
@media screen and (max-width: 767.98px) {
  section {
    padding: 40px 0px;
  }
}

.form-control {
  border: 0.5px solid #407BFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
  padding: 13px 0px 13px 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}
.form-control:focus {
  box-shadow: none;
}
.form-control::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.4;
}
@media screen and (max-width: 767.98px) {
  .form-control {
    margin-bottom: 20px;
    padding: 15px 0px 16px 20px;
  }
}

.form-group {
  margin-bottom: 24px;
}
.form-group label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  margin-bottom: 8px;
}

.form-label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.mobile {
  display: none;
}

.trusted {
  background-color: #e9f9f8;
}
.trusted .big_img {
  max-width: 622px;
}
.trusted h2 {
  margin-bottom: 50px;
}
.trusted .image_menu {
  max-width: 489px;
  min-height: 532px;
  border-radius: 10px;
  border: 1px solid #307BC4;
  padding: 25px;
}
.trusted .image_menu p {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin: 10px 0px 20px 0px;
  cursor: pointer;
}
.trusted .image_menu .img_container {
  max-width: 133px;
  min-height: 70px;
  border-radius: 5px;
  border: 1px;
  background: rgba(46, 114, 180, 0.3098039216);
  border: 1px solid #407BFF;
  text-align: center;
  padding: 4px 0px;
  cursor: pointer;
}
.trusted .image_menu .img_container img {
  height: 63.82px;
}
.trusted .image_menu .grid_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 3;
  gap: 15px;
}
.trusted .image_menu .grid_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 2;
  gap: 23px;
  column-span: 1.5;
}
.trusted .image_menu .grid_2 .img_container {
  max-width: 204px;
}
@media screen and (max-width: 767.98px) {
  .trusted .image_menu {
    max-width: 345px;
    min-height: 413px;
    padding: 20px 15px;
  }
  .trusted .image_menu p {
    line-height: 15px;
    margin: 5px 0px 15px 0px;
  }
  .trusted .image_menu .img_container {
    max-width: 95px;
    min-height: 52px;
    border-radius: 5px;
    border: 1px;
  }
  .trusted .image_menu .img_container img {
    height: 43.82px;
  }
}
.trusted .right {
  padding-left: 112px;
}
@media screen and (max-width: 767.98px) {
  .trusted .right {
    padding-left: 12px;
  }
}
@media screen and (max-width: 767.98px) {
  .trusted h2 {
    margin-bottom: 30px;
    align-self: flex-start;
    line-height: 29px;
  }
  .trusted .big_img {
    height: 407px;
    margin-bottom: 40px;
  }
  .trusted .col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }
}

.why .big_img {
  width: 458px;
}
.why h2 {
  margin-bottom: 30px;
}
.why h3 {
  margin-bottom: 10px;
}
.why .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.why .right div {
  margin-bottom: 16px;
}
@media screen and (max-width: 767.98px) {
  .why .big_img {
    width: 345px;
    align-self: center;
  }
  .why h2 {
    margin: 31px 0px 16px 0px;
  }
  .why h3 {
    margin-bottom: 5px;
  }
  .why .col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: left;
  }
  .why .right div {
    margin-bottom: 7px;
  }
}

.healthcare {
  background-image: url("./images/healthcare.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.healthcare h2 {
  margin-bottom: 30px;
}
.healthcare img {
  width: 428.15px;
}
.healthcare .col-md-7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: unset;
}
.healthcare .col-md-5 {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
}
@media screen and (max-width: 767.98px) {
  .healthcare {
    background-image: url("./images/healthcare.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-position: right;
  }
  .healthcare .col-md-7 {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
    margin-bottom: 80px;
  }
  .healthcare img {
    width: 345px;
  }
}

.bringing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.bringing .big_img {
  width: 626.83px;
  padding-left: 20px;
}
.bringing .right {
  width: 750px;
  height: 335px;
  padding-left: 48px;
  border-radius: 167.5px 0px 0px 167.5px;
  background: linear-gradient(93.61deg, #2E74B7 10.08%, #1F405A 90.58%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding-left: 88px;
  padding-right: 61px;
}
.bringing .right h3 {
  align-self: normal;
}
.bringing .right p {
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  .bringing {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }
  .bringing .big_img {
    width: 355.16px;
    margin-bottom: 40px;
    padding-left: 0px;
  }
  .bringing .right {
    width: 345px;
    height: 338px;
    border-radius: 10px;
    padding: 40px 15px;
  }
  .bringing .right p {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
  }
  .bringing .right h3 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

.timeline_section {
  background-image: url("./images/how.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.timeline {
  position: relative;
  padding: 0%;
}
.timeline h2 {
  position: absolute;
  width: 134px;
  height: 134px;
  border-radius: 50%;
  background-color: rgba(64, 123, 255, 0.4);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 50px;
  font-weight: 600;
}
.timeline .left {
  left: 0;
  padding: 0px 50px 110px 0px;
}
.timeline .left h2 {
  left: 100%;
  margin-left: 50px;
}
.timeline .left .content {
  margin-left: auto;
}
.timeline .right {
  left: 50%;
  padding: 0px 0px 110px 50px;
}
.timeline .right h2 {
  right: 100%;
  margin-right: 50px;
}
.timeline .right::after {
  left: 0px;
}
.timeline .content {
  max-width: 413px;
  height: 197px;
  border-radius: 10px;
  border: 0.3px solid #407BFF;
  padding: 20px 14px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timeline .tc {
  position: relative;
  background-color: inherit;
  width: 50%;
}
.timeline .tc:last-child {
  padding-bottom: 0%;
}
.timeline .tc:hover h2 {
  background-color: #407BFF;
}
.timeline .tc:hover::after {
  background-color: #407BFF;
}
@media screen and (max-width: 767.98px) {
  .timeline {
    text-align: center;
  }
  .timeline h2 {
    font-family: "Roboto", sans-serif;
    font-size: 30px;
    font-weight: 600;
    width: 58px;
    height: 58px;
  }
  .timeline .content {
    min-width: 270px;
    height: 191px;
    border-radius: 10px;
    padding: 15px;
  }
  .timeline .content p {
    line-height: 20px;
    margin-bottom: 0%;
    font-size: 13px;
  }
  .timeline .content .quick {
    font-size: 16px;
  }
  .timeline .content h3 {
    margin-bottom: 5px;
  }
  .timeline .left {
    padding: 0px 0px 50px 8px;
    left: 22%;
  }
  .timeline .left h2 {
    left: -70px;
    margin-left: 0%;
  }
  .timeline .left .content {
    margin-left: 0%;
  }
  .timeline .right {
    left: 22%;
    padding: 0px 0px 50px 8px;
  }
  .timeline .right h2 {
    margin-right: 12px;
  }
}

.tc-parent {
  margin-bottom: 110px;
}
.tc-parent:hover .tc h2 {
  background-color: #407BFF;
}
.tc-parent:hover .tc::after {
  background-color: #407BFF;
}
@media screen and (max-width: 767.98px) {
  .tc-parent {
    margin-bottom: 50px;
  }
}

.tc::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 197px;
  background-color: rgba(64, 123, 255, 0.4);
  top: 0;
  left: 100%;
  margin-left: -5px;
  z-index: 1;
}
@media screen and (max-width: 767.98px) {
  .tc::after {
    left: 0%;
    width: 2px;
    height: 191px;
    margin-left: -3px;
  }
}

.timeline::after {
  content: "";
  position: absolute;
  width: 2px;
  background-color: rgba(64, 123, 255, 0.4);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}
@media screen and (max-width: 767.98px) {
  .timeline::after {
    left: 22%;
  }
}

.home {
  position: relative;
  margin-top: 60px;
  text-align: center;
}
.home .absolute_img {
  background-image: url("./images/home.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 393px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.home .big_img {
  width: 518.71px;
}
.home .grid_4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 94px;
  margin-bottom: 60px;
}
.home .grid_card {
  max-width: 294px;
  min-height: 252px;
  border-radius: 5px;
  background: #FFFFFF;
  transition: all 0.5s linear;
}
.home .grid_card .top {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: flex-start;
  gap: 20px;
  text-align: left;
}
.home .grid_card .top h3 {
  align-self: center;
  margin-bottom: 0px;
}
.home .grid_card .top span {
  width: 85px;
  height: 81px;
  border-radius: 5px 0px 0px 0px;
  background-color: #407BFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}
.home .grid_card .top span h2 {
  margin-bottom: 0px;
}
.home .grid_card h4 {
  padding: 25px 31px 0px 31px;
  text-align: left;
}
.home .grid_card:hover {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
}
.home .left {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home ul {
  padding-left: 0px;
  list-style-type: none;
}
.home ul li {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
}
.home ul li:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2D51A0;
  margin-right: 9px;
  margin-top: 5px;
  flex-shrink: 0;
}
@media screen and (max-width: 767.98px) {
  .home {
    margin-top: 0px;
  }
  .home h2 {
    margin-bottom: 5px;
    line-height: 30px;
  }
  .home .grid_4 {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
  .home .grid_4 .grid_card {
    margin: auto;
  }
  .home .grid_4 .grid_card h3 {
    font-weight: 500;
  }
  .home .grid_4 .grid_card h4 {
    padding: 34px 20px 0px 20px;
    line-height: 18.75px;
  }
  .home .left h2 {
    margin-bottom: 15px;
    margin-top: 30px;
  }
  .home ul {
    margin-bottom: 30px;
  }
  .home ul li {
    margin-bottom: 10px;
    text-align: left;
  }
  .home h4 {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .home .absolute_img {
    height: 1250px;
  }
}

.our {
  background-image: url("./images/our.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 541px;
  text-align: center;
}
.our h2 {
  margin-bottom: 50px;
}
.our .grid_3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 14px;
}
.our .grid_3 .grid_card {
  max-width: 382px;
  border-radius: 10px;
  margin-bottom: 16px;
}
.our .grid_3 .grid_card:hover {
  box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.2509803922);
}
.our .grid_3 p {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}
@media screen and (max-width: 767.98px) {
  .our {
    height: auto;
  }
  .our h2 {
    margin-bottom: 30px;
  }
  .our .grid_3 {
    grid-template-columns: 1fr;
  }
  .our .grid_3 .grid_card {
    margin: auto;
    margin-bottom: 15px;
  }
}

.testimonials {
  background-image: url("./images/testimonial_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
  text-align: center;
}
.testimonials .title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: #307BC4;
  margin-bottom: 13px;
  text-transform: uppercase;
}
.testimonials .home-title {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 15.48px;
  text-align: center;
  margin-bottom: 50px;
}
.testimonials h2 {
  margin-bottom: 52px;
}
.testimonials .t_dots {
  padding-left: 0px;
}
.testimonials .t_dots button:before {
  color: white;
  opacity: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.testimonials .t_dots .slick-active button:before {
  color: #407BFF;
  border: 1px solid #407BFF;
}
.testimonials .slide_item {
  max-width: 600px;
  min-height: 294.33px;
  border-radius: 20px;
  background-color: #F5F7FA;
  text-align: left;
  padding: 25px;
}
.testimonials .slide_item img {
  width: 56px;
  margin-bottom: 16px;
}
.testimonials .slide_item h4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  margin-bottom: 32px;
  line-height: 20px;
}
.testimonials .slide_item .client {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: center;
  gap: 15px;
}
.testimonials .slide_item .client img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-bottom: 0px;
}
.testimonials .slide_item .client div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}
.testimonials .slide_item .client div h4 {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 600;
  line-height: 28.34px;
  text-align: left;
  margin-bottom: 3.66px;
}
.testimonials .slide_item .client div p {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-bottom: 0px;
}
@media screen and (max-width: 767.98px) {
  .testimonials .slide_item {
    border-radius: 10px;
    padding: 15px;
  }
  .testimonials .slide_item img {
    margin-bottom: 24px;
  }
  .testimonials .slide_item h4 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 767.98px) {
  .testimonials .title {
    font-size: 25px;
    font-weight: 600;
    line-height: 29.3px;
    text-align: center;
    color: black;
    text-transform: none;
    margin-bottom: 30px;
  }
  .testimonials h2 {
    margin-bottom: 30px;
  }
}

.slide_item_parent {
  padding-right: 24px;
}
@media screen and (max-width: 767.98px) {
  .slide_item_parent {
    padding-right: 0px;
  }
}

.contact form {
  margin: 50px 0px;
}
.contact .big_img {
  width: 375px;
}
@media screen and (max-width: 767.98px) {
  .contact {
    text-align: center;
    padding-bottom: 0px;
  }
  .contact h2 {
    margin-bottom: 30px;
    text-align: left;
  }
  .contact .big_img {
    width: 351px;
  }
  .contact .ord_1 {
    order: -1;
  }
  .contact button {
    margin-top: 10px;
    width: 100%;
    align-self: center;
  }
}

@media screen and (max-width: 767.98px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}