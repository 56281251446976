h1 {
  font-family: "Roboto", sans-serif;
  font-size: 48px;
  font-weight: bold;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 24px;
}
@media screen and (max-width: 767.98px) {
  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 29px;
  }
}

h3 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  h3 {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
}

h4 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  h4 {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

h5 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

p {
  font-family: "Roboto", sans-serif;
  line-height: 28px;
}

.btn-primary {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #407BFF;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn-primary:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-primary {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-primary:hover {
  background-color: #2769ff;
}

.btn-secondary {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #D2E7F0;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  width: 300px;
}
.btn-secondary:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-secondary {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-secondary:hover {
  background-color: #bfddea;
}

.btn-default {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  color: #407BFF;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  width: 300px;
}
.btn-default:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-default {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-default:hover {
  background-color: #f2f2f2;
}

.btn_primary_1 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #407BFF;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn_primary_1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_1 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_primary_1:hover {
  background-color: #2769ff;
}

.btn_primary_gradient {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  width: 300px;
  background: linear-gradient(94.75deg, #2E74B6 3.87%, #1F405A 84.77%);
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.btn_primary_gradient:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_gradient {
    border-radius: 5px;
    width: 200px;
  }
}

.btn_primary_2 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #D2E7F0;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn_primary_2:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_2 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_primary_2:hover {
  background-color: #bfddea;
}

.btn_secondary_1 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #0C1220;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 300px;
}
.btn_secondary_1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_secondary_1 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_secondary_1:hover {
  background-color: #05080d;
}

.btn_secondary_2 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #000;
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 158px;
}
.btn_secondary_2:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_secondary_2 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_secondary_2:hover {
  background-color: #f2f2f2;
}

.arrow_btn {
  border: none;
  background-color: #FFFFFF;
  color: #407BFF;
  width: 57px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}

.icon_round {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2509803922);
  box-shadow: 0px -1px 11px 0px rgba(0, 0, 0, 0.2509803922);
  color: #407BFF;
}

.icon_parent {
  width: 40px;
  color: #407BFF;
}

.row_between_center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row_center {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: center;
}

.row_centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.col_centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex_1 {
  flex: 1;
}

.h_100 {
  height: 100%;
}

.w_100 {
  width: 100%;
}

section {
  padding: 60px 0px;
}
@media screen and (max-width: 767.98px) {
  section {
    padding: 40px 0px;
  }
}

.form-control {
  border: 0.5px solid #407BFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
  padding: 13px 0px 13px 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}
.form-control:focus {
  box-shadow: none;
}
.form-control::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.4;
}
@media screen and (max-width: 767.98px) {
  .form-control {
    margin-bottom: 20px;
    padding: 15px 0px 16px 20px;
  }
}

.form-group {
  margin-bottom: 24px;
}
.form-group label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  margin-bottom: 8px;
}

.form-label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.container,
.row {
  height: 100%;
}

.grid_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 40px;
}
.grid_4 .grid_item {
  background-color: white;
}

.grid_5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  gap: 40px;
}

ul {
  padding-left: 0;
  list-style: none;
}
ul svg {
  color: #407BFF;
  flex-shrink: 0;
  margin-top: 8px;
}
ul li {
  display: flex;
  gap: 6px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  margin-bottom: 16px;
}

.transforming,
.expert,
.stay,
.heartfelt,
.radiology,
.cuddles,
.from_our,
.your_health,
.your_home,
.wellness,
.diagnostics_top,
.join_our {
  background-image: url("./images/transforming.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 530px;
}
.transforming h2,
.expert h2,
.stay h2,
.heartfelt h2,
.radiology h2,
.cuddles h2,
.from_our h2,
.your_health h2,
.your_home h2,
.wellness h2,
.diagnostics_top h2,
.join_our h2 {
  margin-bottom: 15px;
}
.transforming button,
.expert button,
.stay button,
.heartfelt button,
.radiology button,
.cuddles button,
.from_our button,
.your_health button,
.your_home button,
.wellness button,
.diagnostics_top button,
.join_our button {
  margin-top: 17px;
}
@media screen and (max-width: 767.98px) {
  .transforming,
  .expert,
  .stay,
  .heartfelt,
  .radiology,
  .cuddles,
  .from_our,
  .your_health,
  .your_home,
  .wellness,
  .diagnostics_top,
  .join_our {
    height: unset;
    background-image: url("./images/responsive_bg_dot.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .transforming img.mobile,
  .expert img.mobile,
  .stay img.mobile,
  .heartfelt img.mobile,
  .radiology img.mobile,
  .cuddles img.mobile,
  .from_our img.mobile,
  .your_health img.mobile,
  .your_home img.mobile,
  .wellness img.mobile,
  .diagnostics_top img.mobile,
  .join_our img.mobile {
    width: 345px;
    margin-top: 80px;
  }
  .transforming h2,
  .expert h2,
  .stay h2,
  .heartfelt h2,
  .radiology h2,
  .cuddles h2,
  .from_our h2,
  .your_health h2,
  .your_home h2,
  .wellness h2,
  .diagnostics_top h2,
  .join_our h2 {
    line-height: 29px;
  }
}

@media screen and (max-width: 767.98px) {
  .transforming {
    background-image: url("./images/responsive_bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 0px;
  }
  .transforming .right {
    padding: 0px;
    width: 100%;
  }
  .transforming .right img {
    width: 100%;
  }
}

.beds .big_img,
.nursing .big_img,
.experience .big_img,
.ride .big_img {
  width: 562.13px;
}
.beds h2,
.nursing h2,
.experience h2,
.ride h2 {
  margin-bottom: 17px;
}
.beds h4,
.nursing h4,
.experience h4,
.ride h4 {
  line-height: 19px;
}
.beds .right,
.nursing .right,
.experience .right,
.ride .right {
  padding-left: 86px;
}
.beds .right li svg,
.nursing .right li svg,
.experience .right li svg,
.ride .right li svg {
  margin-top: 4px;
}
@media screen and (max-width: 767.98px) {
  .beds .right,
  .nursing .right,
  .experience .right,
  .ride .right {
    padding-top: 40px;
    padding-left: 12px;
  }
  .beds h2,
  .nursing h2,
  .experience h2,
  .ride h2 {
    margin-bottom: 10px;
    line-height: 29px;
  }
  .beds h4,
  .nursing h4,
  .experience h4,
  .ride h4 {
    margin-bottom: 0px;
  }
}

.cover,
.medicines {
  background-image: url("./images/cover_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.cover h2,
.medicines h2 {
  color: white;
  text-align: center;
  margin-bottom: 50px;
}
.cover h3,
.medicines h3 {
  text-align: center;
  color: white;
  font-weight: 400;
  line-height: 28px;
}
.cover .grid_3,
.medicines .grid_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 40px;
  column-gap: 120px;
  margin-top: 40px;
}
.cover .grid_3 .grid_item,
.medicines .grid_3 .grid_item {
  background-color: white;
  max-width: 331.39px;
  min-height: 83px;
  border-radius: 5px;
  color: black;
  line-height: 28.13px;
  font-weight: 500;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cover .grid_4,
.medicines .grid_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 40px;
  column-gap: 113px;
}
.cover .grid_4 .grid_item,
.medicines .grid_4 .grid_item {
  background-color: white;
  max-width: 234.18px;
  min-height: 83px;
  border-radius: 5px;
  padding: 27px 3px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-bottom: 0px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cover .grid_5,
.medicines .grid_5 {
  column-gap: 28px;
  margin-top: 50px;
}
.cover .grid_5 .grid_item,
.medicines .grid_5 .grid_item {
  max-width: 224px;
  min-height: 83px;
  border-radius: 5px;
  background-color: white;
  color: black;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767.98px) {
  .cover h2,
  .medicines h2 {
    line-height: 29px;
    margin-bottom: 30px;
  }
  .cover .grid_4,
  .cover .grid_3,
  .medicines .grid_4,
  .medicines .grid_3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    gap: 20px;
  }
  .cover .grid_item,
  .medicines .grid_item {
    margin: auto;
    width: 248px;
    height: 82px;
  }
}

.support {
  text-align: center;
}
.support h2 {
  margin-bottom: 16px;
}
.support h3 {
  margin-bottom: 50px;
}
.support .grid_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 40px;
  column-gap: 60px;
}
.support .grid_3 .grid_item {
  max-width: 372px;
  min-height: 275px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 6px rgba(64, 123, 255, 0.1803921569);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.support .grid_3 .grid_item img {
  width: 100%;
  min-height: 275px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(1);
  transition: transform 0.3s ease;
}
.support .grid_3 .grid_item h3 {
  margin-bottom: 0;
  color: white;
  transition: color 0.3s ease;
}
.support .grid_3 .grid_item:hover {
  background-color: rgba(64, 123, 255, 0.1803921569);
}
.support .grid_3 .grid_item:hover img {
  display: block;
  transform: scale(1.2);
}
@media screen and (max-width: 767.98px) {
  .support h3 {
    font-weight: 400;
    margin-bottom: 30px;
  }
  .support .grid_3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    gap: 25px;
  }
  .support .grid_3 .grid_item {
    margin: auto;
    width: 344.5px;
    height: 253px;
  }
  .support .grid_3 .grid_item h3 {
    font-weight: 500;
  }
}

.medical {
  background-image: url("./images/testimonial_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.medical ul {
  list-style: none;
  padding-left: 0px;
}
.medical ul svg {
  color: #407BFF;
  flex-shrink: 0;
  margin-top: 8px;
}
.medical ul li {
  display: flex;
  gap: 6px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  margin-bottom: 16px;
}
.medical .right {
  padding-left: 30px;
}
@media screen and (max-width: 767.98px) {
  .medical h2 {
    margin-bottom: 15px;
  }
  .medical .right {
    margin-top: 40px;
    padding-left: 12px;
  }
}

.backed h2 {
  text-align: center;
  margin-bottom: 50px;
}
.backed .grid_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 32px;
  column-gap: 32px;
}
.backed .grid_4 .grid_item {
  max-width: 285px;
  min-height: 146px;
  border: 0.5px solid rgba(0, 0, 0, 0.5019607843);
  border-radius: 10px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.backed .grid_4 .grid_item img {
  max-height: 146px;
}
.backed .grid_4 .grid_item:nth-of-type(1) {
  background-color: #005788;
}
@media screen and (max-width: 767.98px) {
  .backed h2 {
    margin-bottom: 30px;
  }
  .backed .grid_4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 25px;
    column-gap: 30px;
  }
  .backed .grid_4 .grid_item {
    margin: auto;
    width: 157.38px;
    height: 91px;
    min-height: unset;
  }
  .backed .grid_4 .grid_item img {
    max-height: 87.88px;
  }
}

.check {
  background-image: url("./images/check_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.check h2 {
  text-align: center;
  margin-bottom: 50px;
}
.check .slide_item_parent {
  padding-right: 16px;
}
.check .slide_item {
  max-width: 390px;
  min-height: 519px;
  border-radius: 10px;
  margin-bottom: 50px;
  background-color: white;
  position: relative;
}
.check .slide_item img {
  width: 390px;
  height: 274px;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 33px;
}
.check .slide_item h3 {
  text-align: center;
  margin-bottom: 11px;
  line-height: 28px;
}
.check .slide_item h4 {
  line-height: 18.75px;
  padding: 0px 15px;
}
.check .slide_item button {
  width: 118px;
  margin-left: 15px;
  position: absolute;
  bottom: 40px;
  border-radius: 5px;
  padding: 6px 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.check .slide_item:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.check .t_dots {
  padding-left: 0px;
}
.check .t_dots button:before {
  color: #A9A4A4;
  opacity: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.check .t_dots .slick-active button:before {
  color: #407BFF;
  border: 1px solid #407BFF;
}
@media screen and (max-width: 767.98px) {
  .check h2 {
    margin-bottom: 30px;
  }
  .check .slide_item_parent {
    padding-right: 0px;
  }
  .check .slide_item {
    margin-left: auto;
    margin-right: auto;
  }
}

.expert {
  background-image: url("./images/expert.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  .expert {
    background-image: url("./images/responsive_bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.partner {
  background-image: url("./images/your_bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.partner h2 {
  text-align: center;
  margin-bottom: 50px;
}
.partner .grid_4 {
  column-gap: 24px;
}
.partner .grid_item {
  max-width: 291px;
  min-height: 397px;
  border-radius: 10px;
  padding: 0px;
  text-align: center;
}
.partner .grid_item:hover {
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.2509803922);
}
.partner .grid_item img {
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 28px;
}
.partner .grid_item h3 {
  margin-bottom: 16px;
  padding: 0px 18px;
}
.partner .grid_item h4 {
  padding: 0px 18px;
  line-height: 18.75px;
}

.nursing h4 {
  line-height: 20px;
}
.nursing .right {
  padding-left: 46px;
}
@media screen and (max-width: 767.98px) {
  .nursing .right {
    padding-left: 12px;
  }
}

.why_choose h2 {
  text-align: center;
  margin-bottom: 50px;
}
.why_choose .grid_item {
  max-width: 247px;
  min-height: 0px;
  border-radius: 10px;
  padding: 0px;
}
.why_choose .grid_item img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 16px;
}
.why_choose .grid_item img:hover {
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.2509803922);
}
.why_choose .grid_item h2 {
  margin-bottom: 50px;
}
.why_choose .grid_item h3 {
  font-weight: 400;
}
@media screen and (max-width: 767.98px) {
  .why_choose .grid_4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 30px;
  }
  .why_choose .grid_4 .grid_item {
    max-width: 157.38px;
    min-height: 91px;
    border-radius: 5px;
    height: 100%;
  }
  .why_choose .grid_4 .grid_item h3 {
    font-size: 16px;
  }
}

.experience {
  background-image: url("./images/your_bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.differently h2 {
  text-align: center;
  margin-bottom: 23px;
}
.differently ul {
  list-style: none;
  padding-left: 0px;
}
.differently ul svg {
  color: #407BFF;
  flex-shrink: 0;
  margin-top: 8px;
}
.differently ul li {
  display: flex;
  gap: 6px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  margin-bottom: 16px;
}
.differently button {
  width: 369px;
  height: 56px;
  margin-top: 32px;
}
.differently .big_img {
  width: 562.13px;
}
.differently .right {
  padding-left: 89px;
}
@media screen and (max-width: 767.98px) {
  .differently button {
    max-width: 100%;
    margin-bottom: 40px;
  }
  .differently .right {
    padding-left: 12px;
  }
}

.stay {
  background-image: url("./images/stay_bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  .stay {
    background-image: url("./images/responsive_bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 0px;
  }
  .stay .right {
    padding: 0px;
    width: 100%;
  }
  .stay .right img {
    width: 100%;
  }
}

.medicines h2 {
  margin-bottom: 8px;
}
.medicines h3:nth-of-type(1) {
  margin-bottom: 16px;
}
.medicines .grid_item {
  margin-bottom: 0px;
}
.medicines .slide_item_parent {
  padding-right: 49px;
}
.medicines .slide_item_parent .slide_item {
  background-color: white;
  max-width: 271.77px;
  min-height: 102px;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.medicines .t_dots {
  padding-left: 0px;
}
.medicines .t_dots button:before {
  color: white;
  opacity: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.medicines .t_dots .slick-active button:before {
  color: white;
  border: 1px solid white;
}
@media screen and (max-width: 767.98px) {
  .medicines .slide_item_parent {
    padding-right: 0px;
  }
  .medicines .slide_item_parent .slide_item {
    margin: auto;
    max-width: 224px;
    min-height: 83px;
  }
}

.say h2 {
  margin-bottom: 16px;
}
.say .right {
  padding-left: 98px;
}
@media screen and (max-width: 767.98px) {
  .say .right {
    padding-left: 12px;
    margin-top: 40px;
  }
}

.feeling {
  background-image: url("./images/your_bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.feeling .big_img {
  width: 428.15px;
}
.feeling h2 {
  margin-bottom: 8px;
}
.feeling h3 {
  margin-bottom: 16px;
}
.feeling h4 {
  margin-bottom: 32px;
}
.feeling .right {
  padding-left: 132px;
}
@media screen and (max-width: 767.98px) {
  .feeling .right {
    padding-left: 12px;
  }
  .feeling .col-md-7 {
    margin-bottom: 80px;
  }
}

section .medicines {
  padding: 50px;
  border-radius: 10px;
}
section .medicines h2 {
  margin-bottom: 50px;
}
section .medicines .grid_4 {
  column-gap: 28px;
}
section .medicines .grid_item {
  max-width: 263px;
  min-height: 92px;
  border-radius: 5px;
  color: black;
}

.heartfelt {
  background-image: url("./images/heartfelt.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.compassion .left,
.no_more .left {
  padding-right: 76px;
}
@media screen and (max-width: 767.98px) {
  .compassion .left,
  .no_more .left {
    padding-right: 12px;
    padding-bottom: 40px;
  }
}

.explore,
.making {
  position: relative;
  padding: 0px;
}
.explore h2,
.making h2 {
  text-align: right;
  margin-bottom: 140px;
}
.explore .img_in_bg,
.making .img_in_bg {
  width: 915px;
  height: 215.01px;
  z-index: -1;
  position: absolute;
}
.explore .col-md-8,
.making .col-md-8 {
  padding-top: 55px;
}
.explore .slide_item_parent,
.making .slide_item_parent {
  padding-right: 24px;
  padding-bottom: 10px;
}
.explore .slide_item,
.making .slide_item {
  width: 243px;
  height: 332px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
}
.explore .slide_item h3,
.explore .slide_item h4,
.making .slide_item h3,
.making .slide_item h4 {
  margin-bottom: 23px;
}
.explore .slide_item h3::after,
.explore .slide_item h4::after,
.making .slide_item h3::after,
.making .slide_item h4::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #8DC0F0;
}
.explore .slide_item div,
.making .slide_item div {
  height: 85%;
  overflow-y: scroll;
}
.explore .slide_item div::-webkit-scrollbar,
.making .slide_item div::-webkit-scrollbar {
  width: 1px;
}
.explore .slide_item:hover,
.making .slide_item:hover {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1490196078);
}
.explore .slider_buttons,
.making .slider_buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 10px;
}
.explore .slider_buttons .btn,
.making .slider_buttons .btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #62666A;
  padding: 1px;
}
.explore .t_dots,
.making .t_dots {
  display: none;
}
@media screen and (max-width: 767.98px) {
  .explore,
  .making {
    padding-top: 84px;
  }
  .explore .slide_item_parent,
  .making .slide_item_parent {
    padding: 0px 54px 30px 54px;
  }
  .explore .slide_item,
  .making .slide_item {
    width: 100%;
  }
  .explore .col-md-4 h2,
  .making .col-md-4 h2 {
    display: none;
  }
  .explore .col-md-4 .slider_buttons,
  .making .col-md-4 .slider_buttons {
    justify-content: center;
    margin-bottom: 80px;
  }
}

.care_givers h2 {
  font-family: "Inter", sans-serif;
  margin-bottom: 16px;
}
.care_givers h4 {
  font-family: "Inter", sans-serif;
}

.from_our {
  background-image: url("./images/from_our.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  .from_our {
    background-image: url("./images/responsive_bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 0px;
  }
  .from_our .right {
    padding: 0px;
    width: 100%;
  }
  .from_our .right img {
    width: 100%;
  }
}

.radiology {
  background-image: url("./images/radiology.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  .radiology {
    background-image: url("./images/responsive_bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 0px;
  }
  .radiology .right {
    padding: 0px;
    width: 100%;
  }
  .radiology .right img {
    width: 100%;
  }
}

@media screen and (max-width: 767.98px) {
  .ride h2 {
    margin-bottom: 15px;
  }
  .ride h3 {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 767.98px) {
  .our h2 {
    margin-bottom: 30px;
  }
}

.premier h2,
.sets_apart h2 {
  text-align: center;
}
.premier .grid_item,
.sets_apart .grid_item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #1F5FFF;
  border-radius: 5px;
  font-weight: 500;
  max-width: 271.77px;
  min-height: 102px;
  text-align: center;
  margin: 0px;
}
.premier .grid_item:hover,
.sets_apart .grid_item:hover {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
@media screen and (max-width: 767.98px) {
  .premier h2,
  .sets_apart h2 {
    margin-bottom: 30px;
  }
  .premier .grid_item,
  .sets_apart .grid_item {
    margin: auto;
  }
}

.trans_hc {
  background-image: url("./images/healthcare.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  .trans_hc .right img {
    width: 345px;
  }
}

@media screen and (max-width: 767.98px) {
  .boon h2 {
    margin-bottom: 30px;
  }
}

.title-blue {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #307BC4;
}

@media screen and (max-width: 767.98px) {
  .settings .slide_item_parent {
    padding-bottom: 82px;
  }
}
@media screen and (max-width: 767.98px) {
  .settings .t_dots {
    bottom: 75px;
    position: relative;
  }
}

.cuddles {
  background-image: url("./images/cuddles.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  .cuddles {
    background-image: url("./images/responsive_bg_dot.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.making h2 {
  margin-bottom: 100px;
}

.setting h2 {
  text-align: center;
}
.setting h4 {
  margin-top: 16px;
}
.setting .grid_4 {
  column-gap: 14px;
}
.setting .grid_item {
  text-align: center;
  max-width: 297px;
  min-height: 172px;
}
.setting .grid_item img {
  border-radius: 5px;
}
.setting .grid_item img:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
@media screen and (max-width: 767.98px) {
  .setting .grid_4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 30px;
  }
  .setting .grid_4 .grid_item {
    max-width: 157.38px;
    min-height: 91px;
    border-radius: 5px;
  }
}

.your_health {
  background-image: url("./images/your_health.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  .your_health {
    background-image: url("./images/responsive_bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 0px;
  }
  .your_health .right {
    padding: 0px;
    width: 100%;
  }
  .your_health .right img {
    width: 100%;
  }
}

.no_more h2 {
  margin-bottom: 8px;
}
.no_more h3 {
  margin-bottom: 16px;
}

.your_home {
  background-image: url("./images/your_home.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sets_apart {
  background-image: url("./images/your_bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.sets_apart h2 {
  margin-bottom: 8px;
}
.sets_apart h4 {
  margin-bottom: 50px;
}

.wellness {
  background-image: url("./images/wellness.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  .wellness {
    background-image: url("./images/responsive_bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 0px;
  }
  .wellness .right {
    padding: 0px;
    width: 100%;
  }
  .wellness .right img {
    width: 100%;
  }
}

.why_we {
  background-image: url("./images/check_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.why_we .right {
  padding-left: 170px;
}
.why_we ul li {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.why_we ul li svg {
  margin-right: 7px;
  margin-top: 0px;
}
@media screen and (max-width: 767.98px) {
  .why_we .right {
    padding-left: 12px;
    padding-top: 40px;
  }
}

.health_checks h2 {
  margin-bottom: 50px;
  text-align: center;
}
.health_checks .slide_item_parent {
  padding-right: 49px;
}
.health_checks .slide_item {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  background-color: #D2E7F0;
  max-width: 271.77px;
  min-height: 82px;
  border-radius: 5px;
  border: 1px solid #1F5FFF;
}
.health_checks .slide_item:hover {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.health_checks .t_dots {
  padding-left: 0px;
}
.health_checks .t_dots button:before {
  color: #AED3F0;
  opacity: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.health_checks .t_dots .slick-active button:before {
  color: #407BFF;
  border: 1px solid #407BFF;
}
@media screen and (max-width: 767.98px) {
  .health_checks .slide_item_parent {
    padding-right: 0px;
  }
  .health_checks .slide_item {
    margin: auto;
    max-width: 224px;
    min-height: 83px;
  }
}

.diagnostics_top {
  background-image: url("./images/diagnostics_top.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  .diagnostics_top {
    background-image: url("./images/responsive_bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 0px;
  }
  .diagnostics_top .right {
    padding: 0px;
    width: 100%;
  }
  .diagnostics_top .right img {
    width: 100%;
  }
}

.join_our {
  background-image: url("./images/join_our.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  .join_our {
    background-image: url("./images/responsive_bg_dot.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.its {
  background-image: url("./images/healthcare.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767.98px) {
  .container,
  .row {
    height: unset;
  }
  .grid_4,
  .grid_5 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    gap: 20px;
  }
  .grid_item {
    margin: auto;
    width: 291px;
  }
}