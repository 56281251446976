@import 'mixins';

// Variables
$default-font: 'Roboto', sans-serif;
$Outfit: 'Outfit', sans-serif;
$dm_sans: 'DM Sans', sans-serif;
$inter: 'Inter', sans-serif;
$primary_1: #407BFF;
$primary_light: #407BFF66;
$primary_gradient: linear-gradient(94.75deg, #2E74B6 3.87%, #1F405A 84.77%);
$primary_2: #D2E7F0;
$secondary_1: #0C1220;
$secondary_2: #FFFFFF;
$btn_font_size: 20px;
$btn_secondary_size: 14px;
$btn_secondary_line_height: 20px;
$btn_line_height: 24px;
$p_line_height: 28px;
$h2_default_mb: 24px;
$lh_40: 40px;
$lh_20: 20px;
$footer_bg: #071021;

h1 {
    @include text-style($default-font, 48px, bold);
}

h2 {
    @include text-style($default-font, 36px, 600);
    margin-bottom: $h2_default_mb;

    @media screen and (max-width: 767.98px) {
        @include text-style($default-font, 25px, 600);
        line-height: 29px;
    }
}

h3 {
    @include text-style($default-font, 24px, 600);
    line-height: $btn_line_height;

    @media screen and (max-width: 767.98px) {
        @include text-style($default-font, 20px, 600);
    }
}

h4 {
    @include text-style($default-font, 16px, 400);
    line-height: $btn_line_height;

    @media screen and (max-width: 767.98px) {
        @include text-style($default-font, 16px, 400);
        line-height: $lh_20;
    }
}

h5 {
    @include text-style($default-font, 14px, 400)
}

p {
    font-family: $default-font;
    line-height: $p_line_height
}


// Buttons
.btn-primary {
    @include button($primary_1, #fff, $btn_font_size, $btn_line_height, 500);
}

.btn-secondary {
    @include button($primary_2, #fff, $btn_font_size, $btn_line_height, 400);
}

.btn-default {
    @include button(#fff, $primary_1, $btn_font_size, $btn_line_height, 400);
}

.btn_primary_1 {
    @include button($primary_1, #fff, $btn_font_size, $btn_line_height, 500);
}

.btn_primary_gradient {
    @include gradient_btn($primary_gradient, #fff, $btn_font_size, $btn_line_height, 500);
    font-family: $default-font;
}

.btn_primary_2 {
    @include button($primary_2, #fff, $btn_font_size, $btn_line_height, 500);
}

.btn_secondary_1 {
    @include button($secondary_1, #fff, $btn_secondary_size, $btn_secondary_line_height, 600);
}

.btn_secondary_2 {
    @include button($secondary_2, #000, $btn_secondary_size, $btn_secondary_line_height, 600, 158px);
}

.arrow_btn {
    border: none;
    background-color: #FFFFFF;
    color: $primary_1;
    width: 57px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
}

// icons

.icon_round {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    padding: 15px;

    box-shadow: 0px 4px 8px 0px #00000040;
    box-shadow: 0px -1px 11px 0px #00000040;
    color: $primary_1;
}

.icon_parent {
    width: 40px;
    color: $primary_1;
}

// Flexbox

.row_between_center {
    @include flex-container(row, space-between, center);
}

.row_center {
    @include flex-container(row, unset, center);
}

.row_centered {
    @include flex-container(row, center, center);
}

.col_centered {
    @include flex-container(column, center, center);
}

.flex_1 {
    flex: 1
}

// dimensions

.h_100 {
    height: 100%;
}

.w_100 {
    width: 100%;
}

// sections

section {
    padding: 60px 0px;

    @media screen and (max-width: 767.98px) {
        padding: 40px 0px;
    }
}

// forms

.form-control {
    border: 0.5px solid $primary_1;
    box-shadow: 0px 4px 8px 0px #00000029;
    padding: 13px 0px 13px 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: $btn_line_height;
    margin-bottom: 24px;

    &:focus{
        box-shadow: none;
    }

    &::placeholder{
        @include text-style($default-font, 16px, 400);
        opacity: 0.4;
    }

    @media screen and (max-width: 767.98px) {
        margin-bottom: 20px;
        padding: 15px 0px 16px 20px;
    }
}

.form-group{
    margin-bottom: 24px;
    label{
        @include text-style($default-font, 16px, 400);
        line-height: 18.75px;
        margin-bottom: 8px;
    }
}

.form-label {
    @include text-style($default-font, 14px, 500);
    line-height: 16px;
}