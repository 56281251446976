.policies{
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
}

.policies a{
    color: #2E73B4;
    font-size: 12px;
    font-weight: 400;
}