// Flexbox
@mixin flex-container($direction: row, $justify: flex-start, $align: stretch) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin flex-item($flex: 0 1 auto, $align-self: auto) {
    flex: $flex;
    align-self: $align-self;
}

// grid

@mixin grid-container($columns, $rows: auto, $gap: 10px) {
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
    grid-template-rows: $rows;
    gap: $gap;
}

@mixin grid-item($column-span: 1, $row-span: 1) {
    grid-column: span $column-span;
    grid-row: span $row-span;
}

@mixin grid-item-styles($max-width, $min-height, $border-radius, $padding){
    max-width: $max-width;
    min-height: $min-height;
    border-radius: $border-radius;
    padding: $padding;
}

// Center
@mixin center-both {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin center-x {
    display: flex;
    justify-content: center;
}

@mixin center-y {
    display: flex;
    align-items: center;
}

// Prefix
// @mixin prefix($property, $value) {
//     -webkit-#{$property}: $value;
//     -moz-#{$property}: $value;
//     -ms-#{$property}: $value;
//     -o-#{$property}: $value;
//     #{$property}: $value;
// }

// Text truncation
@mixin text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin limit-vertical-lines($lines) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    overflow: hidden;
}

// Text style
@mixin text-style($font-family, $font-size, $font-weight) {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
}

// placeholder
@mixin placeholder($color) {
    &::placeholder {
        color: $color;
    }
}

// Media queries
@mixin breakpoint($size) {
    @media (max-width: $size) {
        @content;
    }
}

// Buttons

@mixin button_styles {
    padding: 13px 0px;
    border: 1px solid #E3E3E3;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 4px 8px 0px #00000029;
    }

    @media screen and (max-width: 767.98px) {
        border-radius: 5px;
        width: 200px;
    }
}

@mixin button($bg-color, $text-color, $f-size, $l-height, $f-weight, $width: 300px) {
    @include button_styles;

    background-color: $bg-color;
    color: $text-color;
    transition: background-color 0.3s ease;
    font-size: $f-size;
    line-height: $l-height;
    font-weight: $f-weight;
    width: $width;

    &:hover {
        background-color: darken($bg-color, 5%);
    }
}

@mixin gradient_btn($bg, $text-color, $f-size, $l-height, $f-weight, $width: 300px) {
    @include button_styles;
    width: $width;
    background: $bg;
    color: $text-color;
    font-size: $f-size;
    line-height: $l-height;
    font-weight: $f-weight;
}

// backgroung-image

@mixin bg_img($url, $size) {
    background-image: url($url);
    background-size: $size;
    background-position: center;
    background-repeat: no-repeat;
}

// custom container

@mixin custom-container($padding-xs: 0px, $margin-xs: 0px auto, $width-xs: 100%,
    $padding-sm: 12px, $margin-sm: 0px auto, $width-sm: 401px,
    $padding-md: 12px, $margin-md: 24px auto, $width-md: 696px,
    $padding-lg: 12px, $margin-lg: 32px auto, $width-lg: 936px) {
    width: 100%;
    padding: $padding-xs;
    margin: $margin-xs;
    max-width: $width-xs;

    @media (min-width: 576px) {
        padding: $padding-sm;
        margin: $margin-sm;
        max-width: $width-sm;
    }

    @media (min-width: 768px) {
        padding: $padding-md;
        margin: $margin-md;
        max-width: $width-md;
    }

    @media (min-width: 992px) {
        padding: $padding-lg;
        margin: $margin-lg;
        max-width: $width-lg;
    }
}

//  Responsive image 

@mixin zero-padding-full-width-img {
    padding-bottom: 0px;
  
    .right {
      padding: 0px;
      width: 100%;
  
      img {
        width: 100%;
      }
    }
  }
  