@import '../styles/styles.scss';
@import '../styles/mixins.scss';
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.mobile {
    display: none;
}

.trusted {
    background-color: #e9f9f8;

    .big_img {
        max-width: 622px;
    }

    h2 {
        margin-bottom: 50px;
    }

    .image_menu {
        max-width: 489px;
        min-height: 532px;
        border-radius: 10px;
        border: 1px solid #307BC4;
        padding: 25px;

        p {
            font-family: $default-font;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            margin: 10px 0px 20px 0px;
            cursor: pointer;
        }

        .img_container {
            max-width: 133px;
            min-height: 70px;
            border-radius: 5px;
            border: 1px;
            background: #2e72b44f;
            border: 1px solid $primary_1;
            text-align: center;
            padding: 4px 0px;
            cursor: pointer;

            img {
                height: 63.82px;
            }
        }

        .grid_3 {
            @include grid-container(3, auto 3, 15px);
        }

        .grid_2 {
            @include grid-container(2, auto 2, 23px);
            column-span: 1.5;

            .img_container {
                max-width: 204px;
            }
        }

        @media screen and (max-width: 767.98px) {
            max-width: 345px;
            min-height: 413px;
            padding: 20px 15px;


            p {
                line-height: 15px;
                margin: 5px 0px 15px 0px;
            }

            .img_container {
                max-width: 95px;
                min-height: 52px;
                border-radius: 5px;
                border: 1px;

                img {
                    height: 43.82px;
                }
            }
        }
    }

    .right {
        padding-left: 112px;

        @media screen and (max-width: 767.98px) {
            padding-left: 12px;
        }
    }

    @media screen and (max-width: 767.98px) {
        h2 {
            margin-bottom: 30px;
            align-self: flex-start;
            line-height: 29px;
        }

        .big_img {
            height: 407px;
            // min-width: 345px;
            margin-bottom: 40px;
        }

        .col-md-6 {
            @include flex-container(column, unset, center);
        }
    }
}

.why {
    .big_img {
        width: 458px;
    }

    h2 {
        margin-bottom: 30px;
    }

    h3 {
        margin-bottom: 10px;
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: center;

        div {
            margin-bottom: 16px
        }
    }

    @media screen and (max-width: 767.98px) {
        .big_img {
            width: 345px;
            align-self: center;
        }

        h2 {
            margin: 31px 0px 16px 0px;
        }

        h3 {
            margin-bottom: 5px
        }

        .col-md-6 {
            @include flex-container(column, unset, left);
        }

        .right {
            div {
                margin-bottom: 7px;
            }
        }
    }
}

.healthcare {
    @include bg_img('./images/healthcare.png', contain);

    h2 {
        margin-bottom: 30px;
        // line-height: 29px;
    }

    img {
        width: 428.15px;
    }

    .col-md-7 {
        @include flex-container(column, center, unset);
    }

    .col-md-5 {
        @include flex-container(column, right, center);
    }

    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/healthcare.png', cover);
        background-position: right;

        .col-md-7 {
            @include flex-container(column, unset, flex-start);
            margin-bottom: 80px;
        }

        img {
            width: 345px;
        }
    }
}

.bringing {
    @include flex-container(row, space-between, center);

    .big_img {
        width: 626.83px;
        padding-left: 20px;
    }

    .right {
        width: 750px;
        height: 335px;
        padding-left: 48px;
        border-radius: 167.5px 0px 0px 167.5px;
        background: linear-gradient(93.61deg, #2E74B7 10.08%, #1F405A 90.58%);
        @include flex-container(column, center, center);
        color: white;
        padding-left: 88px;
        padding-right: 61px;

        h3 {
            align-self: normal;
        }

        p {
            line-height: $btn_line_height
        }

    }

    @media screen and (max-width: 767.98px) {
        .big_img {
            width: 355.16px;
            margin-bottom: 40px;
            padding-left: 0px;
        }

        .right {
            width: 345px;
            height: 338px;
            border-radius: 10px;
            padding: 40px 15px;

            p {
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 0px;
            }

            h3 {
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 10px
            }
        }

        @include flex-container(column, unset, center);
    }
}

.timeline_section {
    @include bg_img('./images/how.png', cover);
}

.timeline {
    position: relative;
    padding: 0%;

    h2 {
        position: absolute;
        width: 134px;
        height: 134px;
        border-radius: 50%;
        background-color: $primary_light;
        color: white;
        @include flex-container(row, center, center);
        @include text-style($default-font, 50px, 600);
    }

    .left {
        left: 0;
        padding: 0px 50px 110px 0px;

        h2 {
            left: 100%;
            margin-left: 50px;
        }

        .content {
            margin-left: auto;
        }
    }

    .right {
        left: 50%;
        padding: 0px 0px 110px 50px;

        h2 {
            right: 100%;
            margin-right: 50px;
        }
    }

    .right::after {
        left: 0px;
    }

    .content {
        max-width: 413px;
        height: 197px;
        border-radius: 10px;
        border: 0.3px solid $primary_1;
        padding: 20px 14px;
        background-color: white;
        @include flex-container(column, center, center);
    }

    .tc {
        position: relative;
        background-color: inherit;
        width: 50%;

        &:last-child {
            padding-bottom: 0%;
        }

        &:hover {
            h2 {
                background-color: $primary_1;
            }

            &::after {
                background-color: $primary_1;
            }
        }
    }


    @media screen and (max-width: 767.98px) {
        text-align: center;

        h2 {
            @include text-style($default-font, 30px, 600);
            width: 58px;
            height: 58px;
        }

        .content {
            min-width: 270px;
            height: 191px;
            border-radius: 10px;
            padding: 15px;

            p {
                line-height: 20px;
                margin-bottom: 0%;
                font-size: 13px;
            }

            .quick {
                font-size: 16px;
            }

            h3 {
                margin-bottom: 5px;
            }
        }


        .left {
            padding: 0px 0px 50px 8px;
            left: 22%;

            h2 {
                left: -70px;
                // left: -73px;
                margin-left: 0%;
            }

            .content {
                margin-left: 0%;
            }
        }

        .right {
            left: 22%;
            padding: 0px 0px 50px 8px;

            h2 {
                margin-right: 12px;
                // margin-right: 15px;
            }
        }
    }
}

.tc-parent {
    margin-bottom: 110px;

    &:hover {
        .tc {
            h2 {
                background-color: $primary_1;
            }

            &::after {
                background-color: $primary_1;
            }
        }
    }

    @media screen and (max-width: 767.98px) {
        margin-bottom: 50px;
    }
}

.tc::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 197px;
    background-color: $primary_light;
    top: 0;
    left: 100%;
    margin-left: -5px;
    z-index: 1;

    @media screen and (max-width: 767.98px) {
        left: 0%;
        width: 2px;
        height: 191px;
        margin-left: -3px;
    }
}

.timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #407BFF66;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;

    @media screen and (max-width: 767.98px) {
        left: 22%;
    }
}

.home {
    position: relative;
    margin-top: 60px;
    text-align: center;

    .absolute_img {
        @include bg_img('./images/home.png', cover);
        height: 393px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .big_img {
        width: 518.71px;
    }

    .grid_4 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
        margin-top: 94px;
        margin-bottom: 60px;
    }

    .grid_card {
        max-width: 294px;
        min-height: 252px;
        border-radius: 5px;
        background: #FFFFFF;

        .top {
            @include flex-container(row, unset, flex-start);
            gap: 20px;
            text-align: left;

            h3 {
                align-self: center;
                margin-bottom: 0px;
            }

            span {
                width: 85px;
                height: 81px;
                border-radius: 5px 0px 0px 0px;
                background-color: $primary_1;
                @include flex-container(row, center, center);
                color: white;

                h2 {
                    margin-bottom: 0px;
                }
            }
        }

        h4 {
            padding: 25px 31px 0px 31px;
            text-align: left;
        }

        transition: all 0.5s linear;

        &:hover {
            box-shadow: 0px 6px 12px 0px #00000033;
            // transform: rotateY(360deg);
            // scroll-behavior: smooth;
        }
    }

    .left {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    ul {
        padding-left: 0px;
        list-style-type: none;

        li {
            margin-bottom: 24px;
            display: flex;
            align-items: flex-start;

            &:before {
                content: "";
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #2D51A0;
                margin-right: 9px;
                margin-top: 5px;
                flex-shrink: 0;
            }
        }
    }

    @media screen and (max-width: 767.98px) {
        margin-top: 0px;

        h2 {
            margin-bottom: 5px;
            line-height: 30px;
        }

        .grid_4 {
            grid-template-columns: 1fr;
            margin-top: 30px;

            .grid_card {
                margin: auto;

                h3 {
                    font-weight: 500
                }

                h4 {
                    padding: 34px 20px 0px 20px;
                    line-height: 18.75px;
                }
            }
        }

        .left {
            h2 {
                margin-bottom: 15px;
                margin-top: 30px;
            }
        }

        ul {
            li {
                margin-bottom: 10px;
                text-align: left;
            }

            margin-bottom: 30px;
        }

        h4 {
            @include text-style($default-font, 16px, 400);
            line-height: 24px;
        }

        .absolute_img {
            height: 1250px;
        }
    }
}

.our {
    @include bg_img('./images/our.png', cover);
    height: 541px;
    text-align: center;

    h2 {
        margin-bottom: 50px;
    }

    .grid_3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 14px;

        .grid_card {
            max-width: 382px;
            // min-height: 231px;
            border-radius: 10px;

            &:hover {
                box-shadow: 0px 7px 8px 0px #00000040;
            }

            margin-bottom: 16px;
        }

        p {
            font-family: Roboto;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            text-align: center;
        }
    }

    @media screen and (max-width: 767.98px) {
        h2 {
            margin-bottom: 30px;
        }

        .grid_3 {
            grid-template-columns: 1fr;

            .grid_card {
                margin: auto;
                margin-bottom: 15px;
            }
        }

        height: auto;
    }
}

.testimonials {
    @include bg_img('./images/testimonial_bg.png', cover);
    height: 600px;
    text-align: center;

    .title {
        font-family: $default-font;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        color: #307BC4;
        margin-bottom: 13px;
        text-transform: uppercase;
    }

    .home-title {
        @include text-style($default-font, 36px, 600);
        line-height: 15.48px;
        text-align: center;
        margin-bottom: 50px;
    }

    h2 {
        margin-bottom: 52px;
    }

    .t_dots {
        padding-left: 0px;

        button:before {
            color: white;
            opacity: 1;
            border-radius: 50%;
            width: 20px;
            height: 20px;
        }

        .slick-active button:before {
            color: $primary_1;
            border: 1px solid $primary_1;
        }
    }

    .slide_item {
        max-width: 600px;
        min-height: 294.33px;
        border-radius: 20px;
        background-color: #F5F7FA;
        text-align: left;
        // padding: 28px 37px 24px 40px;
        padding: 25px;

        img {
            width: 56px;
            margin-bottom: 16px;
        }

        h4 {
            @include limit-vertical-lines(4);
            margin-bottom: 32px;
            line-height: 20px;
        }

        .client {
            @include flex-container(row, unset, center);
            gap: 15px;

            img {
                width: 56px;
                height: 56px;
                border-radius: 50%;
                margin-bottom: 0px;
            }

            div {
                @include flex-container(column, space-between, left);

                h4 {
                    font-family: Outfit;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 28.34px;
                    text-align: left;
                    margin-bottom: 3.66px;
                }

                p {
                    font-family: DM Sans;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 26px;
                    text-align: left;
                    margin-bottom: 0px;
                }
            }
        }

        @media screen and (max-width: 767.98px) {
            // max-width: 345px;
            border-radius: 10px;
            padding: 15px;

            img {
                margin-bottom: 24px;
            }

            h4 {
                @include limit-vertical-lines(10);
                margin-bottom: 25px;
            }
        }
    }


    @media screen and (max-width: 767.98px) {
        .title {
            font-size: 25px;
            font-weight: 600;
            line-height: 29.3px;
            text-align: center;
            color: black;
            text-transform: none;
            margin-bottom: 30px;
        }

        h2 {
            margin-bottom: 30px;
        }
    }
}

.slide_item_parent {
    padding-right: 24px;

    @media screen and (max-width: 767.98px) {
        padding-right: 0px;
    }
}


.contact {
    form {
        margin: 50px 0px;
    }

    .big_img {
        width: 375px;
    }

    @media screen and (max-width: 767.98px) {
        text-align: center;
        padding-bottom: 0px;

        h2 {
            margin-bottom: 30px;
            text-align: left;
        }

        .big_img {
            width: 351px;
        }

        .ord_1 {
            order: -1;
        }

        button {
            margin-top: 10px;
            width: 100%;
            align-self: center;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .mobile {
        display: block;
    }

    .desktop {
        display: none;
    }
}