h1 {
  font-family: "Roboto", sans-serif;
  font-size: 48px;
  font-weight: bold;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 24px;
}
@media screen and (max-width: 767.98px) {
  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 29px;
  }
}

h3 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  h3 {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
}

h4 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  h4 {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

h5 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

p {
  font-family: "Roboto", sans-serif;
  line-height: 28px;
}

.btn-primary {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #407BFF;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn-primary:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-primary {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-primary:hover {
  background-color: #2769ff;
}

.btn-secondary {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #D2E7F0;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  width: 300px;
}
.btn-secondary:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-secondary {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-secondary:hover {
  background-color: #bfddea;
}

.btn-default {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  color: #407BFF;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  width: 300px;
}
.btn-default:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-default {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-default:hover {
  background-color: #f2f2f2;
}

.btn_primary_1 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #407BFF;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn_primary_1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_1 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_primary_1:hover {
  background-color: #2769ff;
}

.btn_primary_gradient {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  width: 300px;
  background: linear-gradient(94.75deg, #2E74B6 3.87%, #1F405A 84.77%);
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.btn_primary_gradient:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_gradient {
    border-radius: 5px;
    width: 200px;
  }
}

.btn_primary_2 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #D2E7F0;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn_primary_2:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_2 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_primary_2:hover {
  background-color: #bfddea;
}

.btn_secondary_1 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #0C1220;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 300px;
}
.btn_secondary_1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_secondary_1 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_secondary_1:hover {
  background-color: #05080d;
}

.btn_secondary_2 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #000;
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 158px;
}
.btn_secondary_2:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_secondary_2 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_secondary_2:hover {
  background-color: #f2f2f2;
}

.arrow_btn {
  border: none;
  background-color: #FFFFFF;
  color: #407BFF;
  width: 57px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}

.icon_round {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2509803922);
  box-shadow: 0px -1px 11px 0px rgba(0, 0, 0, 0.2509803922);
  color: #407BFF;
}

.icon_parent {
  width: 40px;
  color: #407BFF;
}

.row_between_center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row_center {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: center;
}

.row_centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.col_centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex_1 {
  flex: 1;
}

.h_100 {
  height: 100%;
}

.w_100 {
  width: 100%;
}

section {
  padding: 60px 0px;
}
@media screen and (max-width: 767.98px) {
  section {
    padding: 40px 0px;
  }
}

.form-control {
  border: 0.5px solid #407BFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
  padding: 13px 0px 13px 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}
.form-control:focus {
  box-shadow: none;
}
.form-control::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.4;
}
@media screen and (max-width: 767.98px) {
  .form-control {
    margin-bottom: 20px;
    padding: 15px 0px 16px 20px;
  }
}

.form-group {
  margin-bottom: 24px;
}
.form-group label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  margin-bottom: 8px;
}

.form-label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}