.radio-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-container h4{
  margin-bottom: 0px;
}

.circle {
  width: 13px;
  height: 13px;
  border: 2px solid rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #407BFF;
  border-radius: 50%;
}
