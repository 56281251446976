@import '../styles/mixins.scss';
@import '../styles/styles.scss';

.container,
.row {
    height: 100%;
}

.grid_4 {
    @include grid-container(4, 1fr, 40px);

    .grid_item {
        background-color: white;
    }
}

.grid_5 {
    @include grid-container(5, 1fr, 40px);
}

ul {
    padding-left: 0;
    list-style: none;

    svg {
        color: $primary_1;
        flex-shrink: 0;
        margin-top: 8px;
    }

    li {
        display: flex;
        gap: 6px;
        @include text-style($default-font, 16px, 400);
        line-height: 18.75px;
        margin-bottom: 16px;
    }
}

// HOME ICU SETUP

.transforming,
.expert,
.stay,
.heartfelt,
.radiology,
.cuddles,
.from_our,
.your_health,
.your_home,
.wellness,
.diagnostics_top,
.join_our {
    @include bg_img('./images/transforming.webp', cover);
    height: 530px;

    h2 {
        margin-bottom: 15px;
    }

    button {
        margin-top: 17px;
    }

    @media screen and (max-width: 767.98px) {
        height: unset;
        @include bg_img('./images/responsive_bg_dot.webp', cover);

        img.mobile {
            width: 345px;
            margin-top: 80px;
        }

        h2 {
            line-height: 29px;
        }
    }
}

.transforming {
    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/responsive_bg.webp', cover);
        @include zero-padding-full-width-img;
    }
}

.beds,
.nursing,
.experience,
.ride {
    .big_img {
        width: 562.13px;
    }

    h2 {
        margin-bottom: 17px;
    }

    h4 {
        line-height: 19px;
    }

    .right {
        padding-left: 86px;

        li {
            svg {
                margin-top: 4px;
            }
        }
    }

    @media screen and (max-width: 767.98px) {
        .right {
            padding-top: 40px;
            padding-left: 12px
        }

        h2 {
            margin-bottom: 10px;
            line-height: 29px;
        }

        h4 {
            margin-bottom: 0px
        }
    }
}

.cover,
.medicines {

    @include bg_img('./images/cover_bg.png', cover);

    h2 {
        color: white;
        text-align: center;
        margin-bottom: 50px;
    }

    h3 {
        text-align: center;
        color: white;
        font-weight: 400;
        line-height: 28px;
    }

    .grid_3 {
        @include grid-container(3, 1fr, 40px);
        column-gap: 120px;
        margin-top: 40px;

        .grid_item {
            background-color: white;
            max-width: 331.39px;
            min-height: 83px;
            border-radius: 5px;
            color: black;
            line-height: 28.13px;
            font-weight: 500;
            margin-bottom: 0px;
            @include flex-container(row, center, center);
        }
    }

    .grid_4 {
        @include grid-container(4, 1fr, 40px);
        column-gap: 113px;

        .grid_item {
            background-color: white;
            max-width: 234.18px;
            min-height: 83px;
            border-radius: 5px;
            padding: 27px 3px;
            line-height: 28px;
            font-weight: 500;
            letter-spacing: 0px;
            margin-bottom: 0px;
            color: black;
            @include flex-container(row, center, center);
        }
    }

    .grid_5 {
        column-gap: 28px;
        margin-top: 50px;

        .grid_item {
            max-width: 224px;
            min-height: 83px;
            border-radius: 5px;
            background-color: white;
            color: black;
            font-weight: 500;
            @include flex-container(row, center, center);
        }
    }

    @media screen and (max-width: 767.98px) {
        h2 {
            line-height: 29px;
            margin-bottom: 30px;
        }

        .grid_4,
        .grid_3 {
            @include grid-container(1, 1fr, 20px);

        }

        .grid_item {
            margin: auto;
            width: 248px;
            height: 82px;
        }
    }
}

.support {
    text-align: center;

    h2 {
        margin-bottom: 16px
    }

    h3 {
        margin-bottom: 50px;
    }

    .grid_3 {
        @include grid-container(3, 1fr, 40px);
        column-gap: 60px;

        .grid_item {
            max-width: 372px;
            min-height: 275px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 6px #407BFF2E;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            // background-color: #407BFF2E;

            img {
                width: 100%;
                min-height: 275px;
                border-radius: 10px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                transform: scale(1);
                transition: transform 0.3s ease;
            }

            h3 {
                margin-bottom: 0;
                color: white;
                transition: color 0.3s ease;
            }

            &:hover {
                background-color: #407BFF2E;

                img {
                    display: block;
                    transform: scale(1.2);
                }

            }
        }

    }

    @media screen and (max-width: 767.98px) {
        h3 {
            font-weight: 400;
            margin-bottom: 30px;
        }

        .grid_3 {
            @include grid-container(1, 1fr, 25px);

            .grid_item {
                margin: auto;
                width: 344.5px;
                height: 253px;

                h3 {
                    font-weight: 500;
                }
            }
        }

    }
}

.medical {
    @include bg_img('./images/testimonial_bg.png', cover);

    ul {
        list-style: none;
        padding-left: 0px;

        svg {
            color: $primary_1;
            flex-shrink: 0;
            margin-top: 8px;
        }

        li {
            display: flex;
            gap: 6px;
            @include text-style($default-font, 16px, 400);
            line-height: 18.75px;
            margin-bottom: 16px;
        }
    }

    .right {
        padding-left: 30px;
    }

    @media screen and (max-width: 767.98px) {
        h2 {
            margin-bottom: 15px;
        }

        .right {
            margin-top: 40px;
            padding-left: 12px;
        }
    }
}

.backed {
    h2 {
        text-align: center;
        margin-bottom: 50px;
    }

    .grid_4 {
        @include grid-container(4, 1fr, 32px);
        column-gap: 32px;

        .grid_item {
            max-width: 285px;
            min-height: 146px;
            border: 0.5px solid #00000080;
            border-radius: 10px;
            padding: 2px;
            @include flex-container(row, center, center);

            img {
                max-height: 146px;
            }
        }

        .grid_item:nth-of-type(1) {
            background-color: #005788
        }
    }

    @media screen and (max-width: 767.98px) {
        h2 {
            margin-bottom: 30px;
        }

        .grid_4 {
            @include grid-container(2, 1fr, 25px);
            column-gap: 30px;

            .grid_item {
                margin: auto;
                width: 157.38px;
                height: 91px;
                min-height: unset;

                img {
                    max-height: 87.88px;
                }
            }

        }
    }
}

.check {

    @include bg_img('./images/check_bg.png', cover);

    h2 {
        text-align: center;
        margin-bottom: 50px;
    }

    .slide_item_parent {
        padding-right: 16px;
    }

    .slide_item {
        max-width: 390px;
        min-height: 519px;
        border-radius: 10px;
        margin-bottom: 50px;
        background-color: white;
        position: relative;

        img {
            width: 390px;
            height: 274px;
            border-radius: 10px 10px 0px 0px;
            margin-bottom: 33px;
        }

        h3 {
            text-align: center;
            margin-bottom: 11px;
            line-height: 28px;
        }

        h4 {
            line-height: 18.75px;
            // margin-bottom: 45px;
            padding: 0px 15px;
        }

        button {
            width: 118px;
            // margin: 0px 15px;
            margin-left: 15px;
            position: absolute;
            bottom: 40px;
            border-radius: 5px;
            padding: 6px 0px;
            @include text-style($default-font, 16px, 400);
            line-height: 20px;
        }

        &:hover {
            box-shadow: 0px 4px 4px 0px #00000040;
        }
    }

    .t_dots {
        padding-left: 0px;

        button:before {
            color: #A9A4A4;
            opacity: 1;
            border-radius: 50%;
            width: 20px;
            height: 20px;
        }

        .slick-active button:before {
            color: $primary_1;
            border: 1px solid $primary_1;
        }
    }

    @media screen and (max-width: 767.98px) {
        h2 {
            margin-bottom: 30px;
        }

        .slide_item_parent {
            padding-right: 0px;
        }

        .slide_item {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// HOME NURSE

.expert {
    @include bg_img('./images/expert.webp', cover);

    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/responsive_bg.webp', cover);
    }
}

.partner {
    @include bg_img('./images/your_bg.webp', cover);

    h2 {
        text-align: center;
        margin-bottom: 50px;
    }

    .grid_4 {
        column-gap: 24px;
    }

    .grid_item {
        @include grid-item-styles(291px, 397px, 10px, 0px);

        &:hover {
            box-shadow: 0px 4px 7px 0px #00000040;
        }

        text-align: center;

        img {
            border-radius: 10px 10px 0px 0px;
            margin-bottom: 28px;
        }

        h3 {
            margin-bottom: 16px;
            padding: 0px 18px;
        }

        h4 {
            padding: 0px 18px;
            line-height: 18.75px;
        }
    }
}

.nursing {
    h4 {
        line-height: 20px;
    }

    .right {
        padding-left: 46px;
    }

    @media screen and (max-width: 767.98px) {
        .right {
            padding-left: 12px;
        }

    }
}

.why_choose {

    h2 {
        text-align: center;
        margin-bottom: 50px;
    }

    .grid_item {
        @include grid-item-styles(247px, 0px, 10px, 0px);

        img {
            width: 100%;
            border-radius: 10px;
            margin-bottom: 16px;

            &:hover {
                box-shadow: 0px 4px 7px 0px #00000040;
            }
        }

        h2 {
            margin-bottom: 50px;
        }

        h3 {
            font-weight: 400;
        }
    }

    @media screen and (max-width: 767.98px) {
        .grid_4 {
            @include grid-container(2, 1fr, 30px);

            .grid_item {
                max-width: 157.38px;
                min-height: 91px;
                border-radius: 5px;
                height: 100%;

                h3 {
                    font-size: 16px;
                }
            }
        }

    }
}

.experience {
    @include bg_img('./images/your_bg.webp', cover);
}

.differently {

    h2 {
        text-align: center;
        margin-bottom: 23px;
    }

    ul {
        list-style: none;
        padding-left: 0px;

        svg {
            color: $primary_1;
            flex-shrink: 0;
            margin-top: 8px;
        }

        li {
            display: flex;
            gap: 6px;
            @include text-style($default-font, 16px, 400);
            line-height: 18.75px;
            margin-bottom: 16px;
        }
    }

    button {
        width: 369px;
        height: 56px;
        margin-top: 32px;
    }

    .big_img {
        width: 562.13px;
    }

    .right {
        padding-left: 89px;
    }

    @media screen and (max-width: 767.98px) {
        button {
            max-width: 100%;
            margin-bottom: 40px;
        }

        .right {
            padding-left: 12px;
        }
    }
}

// MEDICINE HOME DELIVERY

.stay {
    @include bg_img('./images/stay_bg.webp', cover);

    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/responsive_bg.webp', cover);
        @include zero-padding-full-width-img;
    }
}

.medicines {
    h2 {
        margin-bottom: 8px;
    }

    h3 {
        &:nth-of-type(1) {
            margin-bottom: 16px;
        }

    }

    .grid_item {
        margin-bottom: 0px;
    }

    .slide_item_parent {
        padding-right: 49px;

        .slide_item {
            background-color: white;
            max-width: 271.77px;
            min-height: 102px;
            border-radius: 5px;
            @include text-style($default-font, 24px, 500);
            @include flex-container(row, center, center);
        }
    }

    .t_dots {
        padding-left: 0px;

        button:before {
            color: white;
            opacity: 1;
            border-radius: 50%;
            width: 20px;
            height: 20px;
        }

        .slick-active button:before {
            color: white;
            border: 1px solid white;
        }
    }

    @media screen and (max-width: 767.98px) {
        .slide_item_parent {
            padding-right: 0px;

            .slide_item {
                margin: auto;
                max-width: 224px;
                min-height: 83px;
            }
        }
    }
}

.say {
    h2 {
        margin-bottom: 16px;
    }

    .right {
        padding-left: 98px;
    }

    @media screen and (max-width: 767.98px) {
        .right {
            padding-left: 12px;
            margin-top: 40px;
        }
    }
}

.feeling {

    @include bg_img('./images/your_bg.webp', cover);

    .big_img {
        width: 428.15px;
    }

    h2 {
        margin-bottom: 8px;
    }

    h3 {
        margin-bottom: 16px;
    }

    h4 {
        margin-bottom: 32px;
    }

    .right {
        padding-left: 132px;
    }

    @media screen and (max-width: 767.98px) {
        .right {
            padding-left: 12px;
        }

        .col-md-7 {
            margin-bottom: 80px;
        }
    }
}

section .medicines {
    padding: 50px;
    border-radius: 10px;

    h2 {
        margin-bottom: 50px;
    }

    .grid_4 {
        column-gap: 28px;
    }

    .grid_item {
        max-width: 263px;
        min-height: 92px;
        border-radius: 5px;
        color: black;
    }
}

// CARE GIVER

.heartfelt {
    @include bg_img('./images/heartfelt.webp', cover);
}

.compassion,
.no_more {
    .left {
        padding-right: 76px;
    }

    @media screen and (max-width: 767.98px) {
        .left {
            padding-right: 12px;
            padding-bottom: 40px;
        }
    }
}

.explore,
.making {
    position: relative;
    padding: 0px;

    h2 {
        text-align: right;
        margin-bottom: 140px;
    }

    .img_in_bg {
        width: 915px;
        height: 215.01px;
        z-index: -1;
        position: absolute;
    }

    .col-md-8 {
        padding-top: 55px;
    }

    .slide_item_parent {
        padding-right: 24px;
        padding-bottom: 10px;
    }

    .slide_item {
        width: 243px;
        height: 332px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        padding: 20px;

        h3,
        h4 {
            margin-bottom: 23px;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: #8DC0F0;
            }
        }

        div {
            height: 85%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 1px;
            }
        }

        &:hover {
            box-shadow: 0px 4px 10px 0px #00000026;
        }
    }

    .slider_buttons {
        @include flex-container(row, end, center);
        gap: 10px;

        .btn {
            @include flex-container(row, center, center);
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid #62666A;
            padding: 1px;
        }
    }

    .t_dots {
        display: none;
    }

    @media screen and (max-width: 767.98px) {
        padding-top: 84px;

        .slide_item_parent {
            padding: 0px 54px 30px 54px;
        }

        .slide_item {
            width: 100%;
        }

        .col-md-4 {
            h2 {
                display: none;
            }

            .slider_buttons {
                justify-content: center;
                margin-bottom: 80px;
            }
        }
    }
}

.care_givers {
    h2 {
        font-family: $inter;
        margin-bottom: 16px;
    }

    h4 {
        font-family: $inter;
    }
}

// MEDICAL EQUIPMENT

.from_our {
    @include bg_img('./images/from_our.webp', cover);

    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/responsive_bg.webp', cover);
        @include zero-padding-full-width-img;
    }
}

// RADIOLOGY

.radiology {
    @include bg_img('./images/radiology.webp', cover);

    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/responsive_bg.webp', cover);
        @include zero-padding-full-width-img;
    }
}

.ride {
    @media screen and (max-width: 767.98px) {
        h2 {
            margin-bottom: 15px;
        }

        h3 {
            margin-bottom: 16px;
        }
    }
}

.our {
    @media screen and (max-width: 767.98px) {
        h2 {
            margin-bottom: 30px;
        }
    }
}

.premier,
.sets_apart {
    h2 {
        text-align: center;
    }

    .grid_item {
        @include flex-container(row, center, center);
        border: 1px solid #1F5FFF;
        border-radius: 5px;
        font-weight: 500;
        max-width: 271.77px;
        min-height: 102px;
        text-align: center;
        margin: 0px;

        &:hover {
            box-shadow: 0px 0px 4px 0px #00000040;
        }
    }

    @media screen and (max-width: 767.98px) {
        h2 {
            margin-bottom: 30px;
        }

        .grid_item {
            margin: auto;
        }
    }
}

.trans_hc {
    @include bg_img('./images/healthcare.webp', cover);

    .right {
        @media screen and (max-width: 767.98px) {
            img {
                width: 345px;
            }
        }
    }
}

.boon {
    @media screen and (max-width: 767.98px) {
        h2 {
            margin-bottom: 30px;
        }
    }
}

.title-blue {
    @include text-style($default-font, 16px, 500);
    line-height: 28px;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #307BC4;
}
.settings {

    .slide_item_parent{
        @media screen and (max-width: 767.98px) {
            padding-bottom: 82px;
        }
    }

    .t_dots{
        @media screen and (max-width: 767.98px) {
            bottom: 75px;
            position: relative;
        }
    }
}

// BABY CARE SERVICES

.cuddles {
    @include bg_img('./images/cuddles.webp', cover);

    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/responsive_bg_dot.webp', cover);
    }
}

.making {
    h2 {
        margin-bottom: 100px;
    }
}

.setting {
    h2 {
        text-align: center;
    }

    h4 {
        margin-top: 16px;
    }

    .grid_4 {
        column-gap: 14px;
    }

    .grid_item {
        text-align: center;
        max-width: 297px;
        min-height: 172px;

        img {
            border-radius: 5px;

            &:hover {
                box-shadow: 0px 4px 4px 0px #00000040;
            }
        }

    }

    @media screen and (max-width: 767.98px) {
        .grid_4 {
            @include grid-container(2, 1fr, 30px);

            .grid_item {
                max-width: 157.38px;
                min-height: 91px;
                border-radius: 5px;
            }
        }

    }
}

// DOCTOR CONSULTAION

.your_health {
    @include bg_img('./images/your_health.webp', cover);

    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/responsive_bg.webp', cover);
        @include zero-padding-full-width-img;
    }
}

.no_more {
    h2 {
        margin-bottom: 8px;
    }

    h3 {
        margin-bottom: 16px;
    }
}

// PHYSIOTHERAPY

.your_home {
    @include bg_img('./images/your_home.webp', cover);
}

.sets_apart {
    @include bg_img('./images/your_bg.webp', cover);

    h2 {
        margin-bottom: 8px;
    }

    h4 {
        margin-bottom: 50px;
    }
}

//  HOME HEALTH CHECKS

.wellness {
    @include bg_img('./images/wellness.webp', cover);

    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/responsive_bg.webp', cover);
        @include zero-padding-full-width-img;
    }
}

.why_we {
    @include bg_img('./images/check_bg.png', cover);

    .right {
        padding-left: 170px;
    }

    ul {
        li {
            @include flex-container(row, start, center);

            svg {
                margin-right: 7px;
                margin-top: 0px;
            }
        }
    }

    @media screen and (max-width: 767.98px) {
        .right{
            padding-left: 12px;
            padding-top: 40px;
        }
    }
}

.health_checks {
    h2 {
        margin-bottom: 50px;
        text-align: center;
    }

    .slide_item_parent {
        padding-right: 49px;
    }

    .slide_item {
        @include text-style($default-font, 24px, 500);
        @include flex-container(row, center, center);
        line-height: 28px;
        background-color: #D2E7F0;
        max-width: 271.77px;
        min-height: 82px;
        border-radius: 5px;
        border: 1px solid #1F5FFF;

        &:hover {
            box-shadow: 0px 0px 4px 0px #00000040;
        }
    }

    .t_dots {
        padding-left: 0px;

        button:before {
            color: #AED3F0;
            opacity: 1;
            border-radius: 50%;
            width: 20px;
            height: 20px;
        }

        .slick-active button:before {
            color: $primary_1;
            border: 1px solid $primary_1;
        }
    }

    @media screen and (max-width: 767.98px) {
        .slide_item_parent {
            padding-right: 0px;
        }

        .slide_item {
            margin: auto;
            max-width: 224px;
            min-height: 83px;
        }
    }
}

// DIAGNOSTICS

.diagnostics_top {
    @include bg_img('./images/diagnostics_top.webp', cover);

    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/responsive_bg.webp', cover);
        @include zero-padding-full-width-img;
    }
}

// JOIN OUT NETWORK

.join_our {
    @include bg_img('./images/join_our.webp', cover);

    @media screen and (max-width: 767.98px) {
        @include bg_img('./images/responsive_bg_dot.webp', cover);
    }
}

.its {
    @include bg_img('./images/healthcare.webp', cover);
}


@media screen and (max-width: 767.98px) {

    .container,
    .row {
        height: unset;
    }

    .grid_4,
    .grid_5 {
        @include grid-container(1, 1fr, 20px);
    }

    .grid_item {
        margin: auto;
        width: 291px;
        // height: 82px;
    }
}