h1 {
  font-family: "Roboto", sans-serif;
  font-size: 48px;
  font-weight: bold;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 24px;
}
@media screen and (max-width: 767.98px) {
  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 29px;
  }
}

h3 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  h3 {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 600;
  }
}

h4 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (max-width: 767.98px) {
  h4 {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

h5 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

p {
  font-family: "Roboto", sans-serif;
  line-height: 28px;
}

.btn-primary {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #407BFF;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn-primary:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-primary {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-primary:hover {
  background-color: #2769ff;
}

.btn-secondary {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #D2E7F0;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  width: 300px;
}
.btn-secondary:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-secondary {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-secondary:hover {
  background-color: #bfddea;
}

.btn-default {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  color: #407BFF;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  width: 300px;
}
.btn-default:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn-default {
    border-radius: 5px;
    width: 200px;
  }
}
.btn-default:hover {
  background-color: #f2f2f2;
}

.btn_primary_1 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #407BFF;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn_primary_1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_1 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_primary_1:hover {
  background-color: #2769ff;
}

.btn_primary_gradient {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  width: 300px;
  background: linear-gradient(94.75deg, #2E74B6 3.87%, #1F405A 84.77%);
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.btn_primary_gradient:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_gradient {
    border-radius: 5px;
    width: 200px;
  }
}

.btn_primary_2 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #D2E7F0;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  width: 300px;
}
.btn_primary_2:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_primary_2 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_primary_2:hover {
  background-color: #bfddea;
}

.btn_secondary_1 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #0C1220;
  color: #fff;
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 300px;
}
.btn_secondary_1:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_secondary_1 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_secondary_1:hover {
  background-color: #05080d;
}

.btn_secondary_2 {
  padding: 13px 0px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #000;
  transition: background-color 0.3s ease;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 158px;
}
.btn_secondary_2:hover {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
}
@media screen and (max-width: 767.98px) {
  .btn_secondary_2 {
    border-radius: 5px;
    width: 200px;
  }
}
.btn_secondary_2:hover {
  background-color: #f2f2f2;
}

.arrow_btn {
  border: none;
  background-color: #FFFFFF;
  color: #407BFF;
  width: 57px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}

.icon_round {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2509803922);
  box-shadow: 0px -1px 11px 0px rgba(0, 0, 0, 0.2509803922);
  color: #407BFF;
}

.icon_parent {
  width: 40px;
  color: #407BFF;
}

.row_between_center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row_center {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: center;
}

.row_centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.col_centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex_1 {
  flex: 1;
}

.h_100 {
  height: 100%;
}

.w_100 {
  width: 100%;
}

section {
  padding: 60px 0px;
}
@media screen and (max-width: 767.98px) {
  section {
    padding: 40px 0px;
  }
}

.form-control {
  border: 0.5px solid #407BFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1607843137);
  padding: 13px 0px 13px 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}
.form-control:focus {
  box-shadow: none;
}
.form-control::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.4;
}
@media screen and (max-width: 767.98px) {
  .form-control {
    margin-bottom: 20px;
    padding: 15px 0px 16px 20px;
  }
}

.form-group {
  margin-bottom: 24px;
}
.form-group label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  margin-bottom: 8px;
}

.form-label {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.logo {
  width: 144px;
}

.dropdown-toggle {
  width: 332px;
  height: 48px;
  padding: 14px 41px 14px 17px;
  border-radius: 8px;
  border: 1px solid #E3E3E3;
}

nav {
  background-color: white;
  box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.168627451);
}
@media screen and (max-width: 767.98px) {
  nav {
    padding: 15px 0px !important;
  }
}

.location-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 332px;
  min-width: 305px;
  height: 48px;
  padding: 14px 41px 14px 17px;
  border-radius: 8px;
  border: 1px solid #E3E3E3;
  margin-right: 8px;
  flex: 1;
}
.location-box input {
  border: none;
  outline: none;
  margin: 0px 8px 0px 10px;
}

.mobile-back {
  display: none;
}
@media screen and (max-width: 767.98px) {
  .mobile-back {
    display: block;
  }
}

.mobile-location-box {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
  max-width: 199px;
  height: 30px;
  border-radius: 4px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  margin-right: 8px;
  flex: 1;
  display: none;
}
.mobile-location-box input {
  border: none;
  outline: none;
  margin: 0px 5px;
}
@media screen and (max-width: 767.98px) {
  .mobile-location-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.search-container {
  flex: 1;
  margin-right: 24px;
}

.search-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  padding: 14px 41px 14px 17px;
  border-radius: 8px;
  border: 1px solid #E3E3E3;
  flex: 1;
}
.search-box input {
  border: none;
  outline: none;
  margin-left: 14px;
  max-width: 78%;
}

.navbar-nav {
  flex: 1;
}
.navbar-nav svg {
  margin-top: 0px;
  color: #a4a4a4;
}
.navbar-nav li {
  margin-bottom: 0px;
}

.profile {
  display: block;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.profile::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #14BD6F;
  top: 4px;
  right: 4px;
}

.mobile-right {
  gap: 20px;
  display: none;
}
@media screen and (max-width: 767.98px) {
  .mobile-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.modal-content {
  border-radius: 10px;
}

.modal-header h3 {
  margin-bottom: 0px;
}
.modal-header svg {
  margin-left: auto;
  align-items: center;
  cursor: pointer;
}

.modal-body {
  padding: 32px 16px;
  position: relative;
}
.modal-body .close-icon {
  position: absolute;
  right: 8px;
  top: 8px;
}

.underline-pointer {
  text-decoration: underline;
  cursor: pointer;
}

.booking-modal-body .booking-step {
  text-align: center;
  font-weight: 500;
  line-height: 18.75px;
  margin-bottom: 32px;
}
.booking-modal-body form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.booking-modal-body form .w_300 {
  width: 300px;
}
.booking-modal-body form .form-control.mobileno {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 0px 10px 32px;
}
.booking-modal-body form .form-control.mobileno h4 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 16px;
}
.booking-modal-body form .form-control.mobileno input {
  outline: none;
  border: none;
  padding: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
  width: 70%;
}
.booking-modal-body form label {
  align-self: flex-start;
}
.booking-modal-body form .underline-pointer {
  font-weight: 500;
}
.booking-modal-body form .resend {
  line-height: 18.75px;
  margin-left: auto;
  margin-top: 8px;
}
.booking-modal-body .mobile-underline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: underline;
  width: 100%;
  gap: 16px;
  margin-bottom: 24px;
}
.booking-modal-body .mobile-underline h4 {
  margin-bottom: 0px;
}
.booking-modal-body .mobile-underline svg {
  cursor: pointer;
}
.booking-modal-body .mobile-label {
  margin-bottom: 16px;
}
.booking-modal-body input {
  border-radius: 5px;
  padding: 12px 0px 12px 32px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 24px;
}
.booking-modal-body input:last-of-type {
  margin-bottom: 21px;
}
.booking-modal-body button {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  border-radius: 10px;
  padding: 15px 0px;
  width: 300px;
}
.booking-modal-body .success-heading {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #31C07E;
  margin-bottom: 16px;
  margin-top: 11px;
}
.booking-modal-body .booking-id {
  font-weight: 500;
}
.booking-modal-body .further {
  margin-top: 21px;
  margin-bottom: 13px;
  text-align: center;
}
.booking-modal-body .further-actions-container {
  margin: auto;
  width: max-content;
}
.booking-modal-body .further-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #2E72B3;
  gap: 5px;
  margin-bottom: 16px;
}
.booking-modal-body .further-actions:hover {
  text-decoration: underline;
  cursor: pointer;
}

.pointer-disable {
  pointer-events: none;
}

.error {
  color: red;
}

.input-box {
  display: flex;
}
.input-box input,
.input-box textarea {
  margin-bottom: 0px;
}

.input-box > div {
  flex: 1;
}

.loader-container {
  text-align: center;
}

.invalid {
  color: red;
  /* You can customize additional styles here based on your requirements */
}

.invalid-error {
  border: 1px solid red;
  border-radius: 4px;
}